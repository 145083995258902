import styled, { css } from 'styled-components';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { rem } from '~/src/helpers/rem-converter.helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { getArrowDirection, getBodyPosition } from '~/src/components/form-components/tooltip/tooltip.utils';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_1279, MAX_767 } from '~/src/styles/tokens/break-points';
import { TooltipDirectionEnum } from '~/src/components/form-components/tooltip/tooltip.types';

export const TooltipText = styled.div<{ width?: number; direction?: TooltipDirectionEnum; }>`
    position: absolute;
    display: inline;
    z-index: ${Z_INDEX.level1};
    box-sizing: border-box;
    width: ${(p) => rem(p.width || 240)};
    padding: ${rem(10)} ${rem(12)} ${rem(12)};
    visibility: hidden;
    background-color: ${COLORS.backgroundInverted};
    color: ${COLORS.textInverted};
    text-align: left;
    border-radius: ${BORDER_RADIUS.normal8};
    opacity: 0;
    transition: opacity 200ms;
    ${FONTS.sans.regular.size14};
    ${(p) => css`${getBodyPosition(p.direction || TooltipDirectionEnum.TOP, p.width || 240)}}`};

    &:after {
        content: '';
        position: absolute;
        border-width: ${rem(7)};
        border-style: solid;
        ${(p) => css`${getArrowDirection(p.direction || TooltipDirectionEnum.TOP)}}`};
    }

    ${(p) => css`
        ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
                ${getBodyPosition(TooltipDirectionEnum.TOP, p.width || 240)};

                &:after {
                    ${getArrowDirection(TooltipDirectionEnum.TOP)};
                }
            `,
    )}
    `};

    ${(p) => css`
        ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
                ${getBodyPosition(p.direction || TooltipDirectionEnum.TOP, p.width || 240)};

                &:after {
                    ${getArrowDirection(p.direction || TooltipDirectionEnum.TOP)};
                }
            `,
    )}
    `};

    ${(p) => css`
        ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
                width: ${rem(240)};
                ${getBodyPosition(p.direction || TooltipDirectionEnum.TOP, 240)};

                &:after {
                    ${getArrowDirection(p.direction || TooltipDirectionEnum.TOP)};
                }
            `,
    )}
    `};
`;

export const TooltipWrap = styled.div<{ isHover?: boolean; isControlled?: boolean; }>`
    position: relative;
    display: inline-flex;
    height: fit-content;
    align-items: center;
    vertical-align: middle;

    a {
        color: ${COLORS.textDarkPrimaryLink};
        text-decoration: none;
        outline: none;

        &:hover {
            text-decoration: underline;
            text-underline-position: under;
        }
    }

    ${(p) =>
        p.isHover && p.isControlled
            ? css`
                  ${TooltipText} {
                      visibility: visible;
                      opacity: 1;
                  }
              `
            : css`
                  ${TooltipText} {
                      visibility: hidden;
                      opacity: 0;
                  }
              `};


    ${(p) =>
        !p.isControlled
            && css`
            &:hover {
                ${TooltipText} {
                    visibility: visible;
                    opacity: 1;
                }
            }
        `};
`;
