import { useEffect, useMemo, useState, useCallback } from 'react';
import { isClientSide } from '~/src/helpers/ssr.helper';
import { MediaWidthEnum } from '~/src/models/enums/media-width.enum';

type MediaType = {
    mediaQueryString: MediaWidthEnum,
    options: {
        withoutSSR?: boolean;
    };
}

type PropsType = (mediaQueryString: MediaType['mediaQueryString'], options?: MediaType['options']) => boolean[];

export const useMatchMedia: PropsType = (mediaQueryString, options) => {
    const _mql = useMemo<MediaQueryList | null>(() => {
        return isClientSide() ? window.matchMedia(mediaQueryString) : null;
    }, [mediaQueryString]);


    const getMatch = useCallback(() => {
        return _mql ? _mql.matches : false;
    }, [_mql]);

    const [isMatch, setIsMatch] = useState(options?.withoutSSR ? getMatch() : false);

    const handleMediaChange = useCallback((event: { matches: boolean | ((prevState: boolean) => boolean) }) => {
        setIsMatch(event.matches);
    }, []);

    useEffect(() => {
        if (!_mql) {
            return;
        }

        if (!options?.withoutSSR) {
            setIsMatch(getMatch());
        }

        if ('function' === typeof _mql.addEventListener) {
            _mql.addEventListener('change', handleMediaChange);
        }

        return () => {
            if ('function' === typeof _mql.removeEventListener) {
                _mql.removeEventListener('change', handleMediaChange);
            }
        };
    }, [_mql, options?.withoutSSR, handleMediaChange, getMatch]);

    return [isMatch];
};