export const REWARD_TEXT_DATA = {
    title: 'Пример расчёта вознаграждения',
    buttonText: 'Стать Агентом',
    blocks: [
        {
            id: 1,
            text: 'Вы рекомендуете продукт МТС своим\u00A0клиентам'
        },
        {
            id: 2,
            text: 'Ваш клиент оплачивает рекомендованный продукт МТС'
        },
        {
            id: 3,
            text: 'Вы получаете до 20% от\u00A0суммы оплаты\u00A0клиентом',
        },
    ]
};