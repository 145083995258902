import * as Styled from '~/src/components/blocks/main/caption-block-content/caption-block-content.styles';
import { SER_CON_AGENTS_URL } from '~/src/params';

export const connectionStagesStatusData = {
    ARCHIVED: null,
    CONNECTED: {
        getText: (organizationName: string, isMobile?: boolean) => (
            <>
                Вы управляете:{' '}
                {isMobile ? <Styled.OrganizationName>{organizationName}</Styled.OrganizationName> : organizationName}
            </>
        ),
        title: '',
        withIcon: false,
    },
    CAN_CONNECT: {
        title: 'Подпишите оферту',
        getText: (organizationName: string) => (
            <>
                {organizationName},
                <br />
                ваш бизнес-аккаунт прошёл проверку. Подпишите оферту, чтобы завершить подключение к&nbsp;программе
                и&nbsp;начать зарабатывать
            </>
        ),
        withIcon: true,
    },
    DATA_VALIDATION: {
        title: 'Проверка бизнес-аккаунта',
        getText: (organizationName: string) => (
            <>
                {organizationName},
                <br />
                дождитесь окончания проверки бизнес-аккаунта. Затем&nbsp;
                <Styled.LinkToSerCon href={SER_CON_AGENTS_URL}>подпишите&nbsp;оферту, </Styled.LinkToSerCon>
                чтобы завершить подключение к&nbsp;программе и&nbsp;начать зарабатывать
            </>
        ),
        withIcon: true,
    },
};
