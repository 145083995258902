import React, { FC } from 'react';

interface SvgCloseProps {
    width?: number;
    height?: number;
    color?: string;
}

export const SvgClose: FC<SvgCloseProps> = ({ width, height, color, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 12}
            height={height || 12}
            fill="none"
            viewBox="0 0 12 12"
            style={{ cursor: 'pointer' }}
            {...props}
        >
            <path
                fill={color || '#969FA8'}
                d="M.293 10.293a1 1 0 1 0 1.414 1.414L6 7.414l4.293 4.293a1 1 0 0 0 1.414-1.414L7.414 6l4.293-4.293A1 1 0 0 0 10.293.293L6 4.586 1.707.293A1 1 0 1 0 .293 1.707L4.586 6 .293 10.293Z"
            />
        </svg>
    );
};
