import { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { COLORS } from '~/src/styles/tokens/colors';
import {
    TOOLTIP_TOP_LEFT_POSITION_PERCENT,
    TOOLTIP_TOP_RIGHT_POSITION_PERCENT,
} from '~/src/components/form-components/tooltip/tooltip.constants';
import { TooltipDirectionEnum } from '~/src/components/form-components/tooltip/tooltip.types';
import type { SvgQuestionMarkPlacementType } from '~/src/components/form-components/tooltip/tooltip.types';

const defaultArrowTopPositionStyles = css`
    bottom: ${rem(-13)};
    top: auto;
    left: auto;
    right: 50%;
    border-color: ${COLORS.textLightPrimary} transparent transparent transparent;
`;

const defaultBodyTopPositionStyles = css`
    top: auto;
    bottom: 100%;
    left: auto;
    margin: auto auto ${rem(8)} auto;
`;

const arrowDirections = {
    [TooltipDirectionEnum.LEFT]: css`
        bottom: calc(50% - ${rem(7)});
        right: ${rem(-13)};
        left: auto;
        top: auto;
        border-color: transparent transparent transparent ${COLORS.textLightPrimary};
    `,
    [TooltipDirectionEnum.RIGHT]: css`
        top: calc(50% - ${rem(7)});
        right: calc(100% - 1px);
        bottom: auto;
        left: auto;
        border-color: transparent ${COLORS.textLightPrimary} transparent transparent;
    `,
    [TooltipDirectionEnum.TOP]: css`
        ${defaultArrowTopPositionStyles};
    `,
    [TooltipDirectionEnum.TOP_LEFT]: css`
        ${defaultArrowTopPositionStyles};
        right: ${TOOLTIP_TOP_LEFT_POSITION_PERCENT}%;
    `,
    [TooltipDirectionEnum.TOP_RIGHT]: css`
        ${defaultArrowTopPositionStyles};
        right: ${TOOLTIP_TOP_RIGHT_POSITION_PERCENT}%;
    `,
    [TooltipDirectionEnum.BOTTOM]: css`
        ${defaultArrowTopPositionStyles};
        border-color: transparent transparent ${COLORS.textLightPrimary} transparent;
    `,
};

export const getArrowDirection = (direction: TooltipDirectionEnum) => {
    return arrowDirections[direction] || arrowDirections[TooltipDirectionEnum.TOP];
};

export const getBodyPosition = (direction: TooltipDirectionEnum, width: number) => {
    const bodyPositions = {
        [TooltipDirectionEnum.LEFT]: css`
            top: auto;
            right: 0;
            bottom: auto;
            left: auto;
            margin: auto ${rem(22)} auto auto;
        `,
        [TooltipDirectionEnum.RIGHT]: css`
            bottom: auto;
            top: auto;
            right: auto;
            left: auto;
            margin: auto auto auto ${rem(25)};
        `,
        [TooltipDirectionEnum.TOP]: css`
            ${defaultBodyTopPositionStyles};
            right: calc(-${width}px / 2);
        `,
        [TooltipDirectionEnum.TOP_LEFT]: css`
            ${defaultBodyTopPositionStyles};
            right: calc(-${width}px / 100 * ${TOOLTIP_TOP_LEFT_POSITION_PERCENT});
        `,
        [TooltipDirectionEnum.TOP_RIGHT]: css`
            ${defaultBodyTopPositionStyles};
            right: calc(-${width}px / 100 * ${TOOLTIP_TOP_RIGHT_POSITION_PERCENT});
        `,
        [TooltipDirectionEnum.BOTTOM]: css`
            top: -100%;
            bottom: auto;
            right: calc(-${width}px / 2);
            left: auto;
            margin: ${rem(38)} auto auto auto;
        `,
    };
    return bodyPositions[direction] || bodyPositions[TooltipDirectionEnum.TOP];
};

export const getTooltipPlacement = (svgPlacementParams: SvgQuestionMarkPlacementType, tooltipWidth: number) => {
    const { distanceToRight, distanceToLeft } = svgPlacementParams;
    const halfTooltipWidth = tooltipWidth / 2;
    const priorityDirection = distanceToRight < distanceToLeft ? TooltipDirectionEnum.LEFT : TooltipDirectionEnum.RIGHT;

    if (halfTooltipWidth <= distanceToRight && halfTooltipWidth <= distanceToLeft) {
        return TooltipDirectionEnum.TOP;
    }

    if (priorityDirection === TooltipDirectionEnum.LEFT) {
        return TooltipDirectionEnum.TOP_LEFT;
    }

    return TooltipDirectionEnum.TOP_RIGHT;
};
