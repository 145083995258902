import { TypeFilesEnum } from '~/src/models/enums/type-files.enum';
import {
    DOC_TYPES_ARRAY,
    IMG_TYPES_ARRAY,
    PDF_TYPES_ARRAY,
} from '~/src/components/common-components/dropzone-uploader/dropzone-uploader.constants';

export const MAX_MESSAGE_TEXTAREA_LENGTH = 1200;
export const TEXTAREA_HEIGHT = 164;

export const FILE_DATA = [
    {
        title: 'Прикрепите файл',
        id: 1,
        type: TypeFilesEnum.additionLead,
        positionMobile: { right: 0, bottom: -230 },
        position: { right: 0, bottom: -72 },
        counts: 1,
        typesFiles: [...PDF_TYPES_ARRAY, ...IMG_TYPES_ARRAY, ...DOC_TYPES_ARRAY],
        maxSize: 20,
        labelFormat: 'JPEG, JPG, PDF, PNG, DOC, DOCX, PPT, PPTX, XLSX, XLS',
    },
];