import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1279, MAX_1919, MAX_767, MIN_1920 } from '~/src/styles/tokens/break-points';

export const BigTitle = styled.h1<{ marginTop?: number; }>`
    min-width: 50%;
    max-width: ${rem(530)};
    text-transform: uppercase;
    margin-top: ${(p) => (p.marginTop ? rem(p.marginTop) : 0)};
    ${FONTS.wide.medium.size48};
	
	${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.wide.medium.size56};
	        max-width: ${rem(588)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.wide.medium.size44};
	        max-width: ${rem(480)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            ${FONTS.wide.medium.size32};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size28};
        `,
    )};
`;
