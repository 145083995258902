import React, { FC, useEffect, useRef, useState } from 'react';
import * as Styled from './app-sent-notification.styles';

interface AppSentProps {
    title?: string;
    description?: string;
    onAppSentDisappear: () => void;
    className?: string;
}

export const AppSentNotification: FC<AppSentProps> = ({
    title,
    description,
    onAppSentDisappear,
    className,
}) => {
    const [width, setWidth] = useState(0);
    const appSentRef = useRef<HTMLDivElement | null>(null);
    const hideTime = 3000;

    useEffect(() => {
        const timerAppSentOnOff = setTimeout(() => {
            onAppSentDisappear();
        }, hideTime);

        return () => {
            clearTimeout(timerAppSentOnOff);
        };
    }, []);

    useEffect(() => {
        appSentRef.current && setWidth(appSentRef.current.getBoundingClientRect().width);
    }, [appSentRef.current]);

    return (
        <>
            <Styled.BackgroundContainer />
            <Styled.AppSentWrapper>
                <Styled.AppSentNotification
                    className={className}
                    ref={appSentRef}
                    shift={Math.floor(width / 2)}
                >
                    <Styled.Wrapper>
                        <Styled.Title>
                            {title}
                        </Styled.Title>
                        <Styled.Description>
                            {description}
                        </Styled.Description>
                    </Styled.Wrapper>
                </Styled.AppSentNotification>
            </Styled.AppSentWrapper>
        </>
    );
};