import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_767 } from '~/src/styles/tokens/break-points';

export const Skeleton = styled.div<{ isImage?: boolean; isTitle?: boolean; marginTop?: number; }>`
    width: 100%;
    overflow: hidden;
    align-items: ${p => p.isImage ? 'center' : 'stretch'};
    margin-left: auto;
    margin-top: ${p => p.marginTop ? rem(p.marginTop) : 'auto'};
    ${p => !p.isTitle && css`height: ${p.isImage ? '100%' : rem(210)}`};

    ${p => p.isImage && css`
        ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: ${rem(300)};
            height: ${rem(290)};
        `,
    )}`};

    ${p => !p.isImage && !p.isTitle && css`
        ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            height: ${rem(280)};
        `,
    )}`};
`;
