import styled, { css } from 'styled-components';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1279, MAX_767 } from '~/src/styles/tokens/break-points';
import { rem } from '~/src/helpers/rem-converter.helper';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

export const DownloadLinkWrap = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            margin-left: 0;
        `,
    )};
`;

export const DownloadLink = styled.a`
    padding-left: ${rem(10)};
    ${FONTS.compact.medium.size20};
    color: ${COLORS.textPrimaryLinkDark};
    text-decoration: none;
    outline: none;
    position: relative;
    z-index: ${Z_INDEX.level1};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.compact.medium.size17};
        `,
    )};
`;
