import { SVGProps } from 'react';
import { COLORS } from '~/src/styles/tokens/colors';

export const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox={'0 0 32 32'}
        fill={COLORS.textPrimaryLinkDark}
        {...props}
    >
        <path
            fill={props.fill || COLORS.textPrimaryLinkDark}
            fillRule="evenodd"
            d="M.12 12.64c.328-4.78.492-7.171 2.92-9.6C5.47.612 7.86.448 12.64.12 13.74.046 14.87 0 16 0s2.26.045 3.36.12c4.781.328 7.172.492 9.6 2.92 2.428 2.429 2.592 4.82 2.92 9.6.075 1.1.12 2.23.12 3.36s-.045 2.26-.12 3.36c-.328 4.781-.492 7.172-2.92 9.6s-4.819 2.592-9.6 2.92c-1.1.075-2.23.12-3.36.12s-2.26-.045-3.36-.12c-4.781-.328-7.171-.492-9.6-2.92C.612 26.532.448 24.141.12 19.36A49.318 49.318 0 0 1 0 16c0-1.13.045-2.26.12-3.36ZM11 14.5a1.5 1.5 0 0 1 1.5-1.5H15a2.5 2.5 0 0 1 2.5 2.5v8a1.5 1.5 0 1 1-3 0V16h-2a1.5 1.5 0 0 1-1.5-1.5Zm4.5-3.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
            clipRule="evenodd"
        />
    </svg>
);
