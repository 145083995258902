import styled, { css } from 'styled-components';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { rem } from '~/src/helpers/rem-converter.helper';
import type { CommonButtonVisualInterface } from '~/src/components/form-components/main-button/main-button.types';

export interface ButtonPropsInterface {
    colorsSet: CommonButtonVisualInterface;
    [key: string]: unknown;
}

export const Button = styled.button<ButtonPropsInterface>`
    display: flex;
    width: fit-content;
    min-width: ${rem(150)};
    height: ${rem(52)};
    padding: ${rem(14)} ${rem(24)};
    justify-content: center;
    align-content: center;
    align-items: center;
    ${FONTS.sans.medium.size17};
    background-color: ${(p) => p.colorsSet.normal.bgColor};
    color: ${(p) => p.colorsSet.normal.textColor};
    ${(p) =>
        css`
            border: 2px solid ${p.colorsSet.normal.borderColor};
        `};
    border-radius: ${BORDER_RADIUS.normal8};
    cursor: pointer;
    outline: none;
    transition: 200ms;

    &:hover {
        background-color: ${(p) => p.colorsSet.hover.bgColor};
        color: ${(p) => p.colorsSet.hover.textColor};
        ${(p) =>
        css`
            border: 2px solid ${p.colorsSet.hover.borderColor};
        `};
        scale: 102%;
        transition: 200ms;
    }

    &:active {
        background-color: ${(p) => p.colorsSet.pressed.bgColor};
        color: ${(p) => p.colorsSet.pressed.textColor};
        ${(p) =>
        css`
            border: 2px solid ${p.colorsSet.pressed.borderColor};
        `};
        scale: 100%;
        transition: 200ms;
    }

    &:disabled {
        pointer-events: none;
        cursor: default;
        background-color: ${(p) => p.colorsSet.disabled.bgColor};
        color: ${(p) => p.colorsSet.disabled.textColor};
        border: 2px solid transparent;
    }
`;
