import {
    CounterpartyConnectStatusAllEnum,
    CounterpartyConnectStatusEnum,
} from '~/src/models/enums/counterparty-connect-status.enum';
import type { OrganizationInterface } from '~/src/repositories/organizations/types/organizations.types';

export const getConnectedStatusAll = (organization: Array<OrganizationInterface>) => {
    if (organization.every((v) => v.connectingStatus === CounterpartyConnectStatusEnum.CONNECTED)) {
        return CounterpartyConnectStatusAllEnum.EVERY_CONNECTED;
    } else if (organization.some((v) => v.connectingStatus === CounterpartyConnectStatusEnum.CONNECTED)) {
        return CounterpartyConnectStatusAllEnum.SOME_CONNECTED;
    } else if (
        organization.filter((v) => v.connectingStatus === CounterpartyConnectStatusEnum.CONNECTED).length === 0
    ) {
        return CounterpartyConnectStatusAllEnum.NONE_CONNECTED;
    }
    return CounterpartyConnectStatusAllEnum.DEFAULT;
};
