import * as React from 'react';
import { FC, SVGProps } from 'react';

export const SvgArrowReward: FC<SVGProps<SVGSVGElement>> = ({ stroke }) => (
    <svg width={30} height={50} viewBox="0 0 30 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#a)">
            <path
                d="M1.818 48.182a4.5 4.5 0 0 1 0-6.364l15.05-15.05a2.5 2.5 0 0 0 0-3.536L1.818 8.182a4.5 4.5 0 1 1 6.364-6.364l19.495 19.495a5.214 5.214 0 0 1 0 7.374L8.182 48.182a4.5 4.5 0 0 1-6.364 0Z"
                stroke={stroke || '#000'}
            />
        </g>
        <defs>
            <filter
                id="a"
                x={-4}
                y={-4}
                width={37.704}
                height={58}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation={2} />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4441_938679" />
                <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_4441_938679" result="shape" />
            </filter>
        </defs>
    </svg>
);
