import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { authBigTitle } from '~/src/components/blocks/main/caption-block-content/caption-block-content.constants';
import { selectUserProfile } from '~/src/redux/user/user.selectors';
import { connectionStagesStatusData } from './personal-status-block.constants';
import { CounterpartyConnectStatusAllEnum } from '~/src/models/enums/counterparty-connect-status.enum';
import { BigTitle } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/big-title/big-title';
import { DefaultStatusContent } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/default-status-content/default-status-content';
import * as Styled from './personal-status-block.styles';

interface PersonalStatusBlockProps {
    organizationConnectionStatusAll: CounterpartyConnectStatusAllEnum;
    componentButtons: ReactNode;
}

export const PersonalStatusBlock: FC<PersonalStatusBlockProps> = ({
    organizationConnectionStatusAll,
    componentButtons,
}) => {
    const connectionStatusData = connectionStagesStatusData[organizationConnectionStatusAll];
    const { name } = useSelector(selectUserProfile);

    if (connectionStatusData) {
        const { getText } = connectionStatusData;

        return (
            <>
                <BigTitle marginTop={6} bigTitleText={authBigTitle} />
                <Styled.AuthContentWrapper>
                    <Styled.ContentText>{getText(name)}</Styled.ContentText>
                    <Styled.ButtonsArea alignCenterOnMobile={true}>{componentButtons}</Styled.ButtonsArea>
                </Styled.AuthContentWrapper>
            </>
        );
    }

    return <DefaultStatusContent componentButtons={componentButtons} />;
};
