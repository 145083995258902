import { ComponentPropsWithoutRef, FC } from 'react';

export interface CheckedIconProps extends ComponentPropsWithoutRef<'svg'> {
    size: number;
    checked: boolean;
    disabled: boolean;
    indeterminate?: boolean;
}

export const CheckboxIcon: FC<CheckedIconProps> = ({ size, indeterminate, checked, disabled, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={size} height={size} viewBox={'0 0 32 32'} {...rest}>
        {!checked && !disabled ? (
            <path
                fill="#fff"
                stroke="#8D969F"
                strokeWidth={1.5}
                d="M4.856 13.288c.263-3.538.4-4.985 1.922-6.508 1.523-1.523 2.97-1.658 6.508-1.922A36.725 36.725 0 0 1 16 4.752c.911 0 1.825.04 2.714.106 3.538.264 4.985.4 6.508 1.922 1.523 1.523 1.659 2.97 1.922 6.508.066.89.106 1.803.106 2.714s-.04 1.825-.106 2.714c-.263 3.538-.4 4.985-1.922 6.508-1.523 1.523-2.97 1.659-6.508 1.922-.889.066-1.803.106-2.714.106s-1.825-.04-2.714-.106c-3.538-.263-4.985-.4-6.508-1.922-1.523-1.523-1.659-2.97-1.922-6.508a36.725 36.725 0 0 1-.106-2.714c0-.911.04-1.825.106-2.714Z"
            />
        ) : (
            <>
                <path
                    fill={disabled ? '#BCC3D0' : '#FF0032'}
                    fillOpacity={disabled ? 0.5 : 1}
                    d="M4.108 13.233c.26-3.488.39-5.232 2.14-6.983 1.75-1.75 3.494-1.88 6.982-2.14A37.477 37.477 0 0 1 16 4.002c.933 0 1.865.041 2.77.108 3.488.26 5.232.39 6.982 2.14s1.88 3.495 2.14 6.983c.067.904.108 1.836.108 2.77 0 .932-.04 1.865-.108 2.769-.26 3.488-.39 5.232-2.14 6.982s-3.494 1.88-6.982 2.14c-.905.067-1.837.108-2.77.108s-1.865-.04-2.77-.108c-3.488-.26-5.232-.39-6.982-2.14s-1.88-3.494-2.14-6.982A37.477 37.477 0 0 1 4 16.002c0-.932.04-1.865.108-2.77Z"
                />
                {checked && !indeterminate && (
                    <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#fff"
                        fillRule="evenodd"
                        d="M21.384 11.616a1.25 1.25 0 0 1 0 1.768l-6 6a1.25 1.25 0 0 1-1.768 0l-2.5-2.5a1.25 1.25 0 0 1 1.768-1.768l1.616 1.616 5.116-5.116a1.25 1.25 0 0 1 1.768 0Z"
                        clipRule="evenodd"
                    />
                )}
                {indeterminate && (
                    <path
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#fff"
                        fillRule="evenodd"
                        d="M12 14.75a1.25 1.25 0 1 0 0 2.5h8a1.25 1.25 0 1 0 0-2.5h-8Z"
                        clipRule="evenodd"
                    />
                )}
            </>
        )}
    </svg>
);
