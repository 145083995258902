import { GtmService } from '~/src/services/web-analytics.service';
import { getAnalyticsEventData } from '~/src/helpers/web-analytics-events-data.helper';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsButtonLocationEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
} from '~/src/models/enums/web-analytics.enum';
import { transliterate } from '~/src/helpers/transliterate.helper';
import { FEEDBACK_ERRORS_DATA } from '~/src/components/common-components/feedback-form/feedback-form.constants';

export const sendCustomButtonClickAnalytics = (label: string) => {
    GtmService.send(
        getAnalyticsEventData({
            event: WebAnalyticsEventEnum.vntForm,
            eventCategory: WebAnalyticsEventCategoryEnum.appForm,
            eventAction: WebAnalyticsEventActionEnum.buttonClick,
            eventLabel: transliterate(label),
            buttonLocation: WebAnalyticsButtonLocationEnum.popup,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};

export const sendCustomCheckboxClickAnalytics = () => {
    GtmService.send(
        getAnalyticsEventData({
            event: WebAnalyticsEventEnum.vntForm,
            eventCategory: WebAnalyticsEventCategoryEnum.appForm,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: 'checkbox_soglasie_na_obrabotku_pnd',
            buttonLocation: WebAnalyticsButtonLocationEnum.popup,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};

export const sendFieldsFillingAnalytics = (
    label: string,
    isConfirmed: string | null,
    isEmailField: boolean,
    isRequiredField: boolean,
    isValidEmailField: boolean,
    isPhoneField: boolean,
    isValidPhoneField: boolean,
) => {
    const wrapperConfirmed = (analyticEvent: string | boolean | null) => {
        return analyticEvent ? WebAnalyticsEventActionEnum.confirmed : WebAnalyticsEventActionEnum.rejected;
    };

    const wrapperInteractions = (analyticEvent: string | boolean | null) => {
        return analyticEvent ? WebAnalyticsActionGroupEnum.interactions : WebAnalyticsActionGroupEnum.nonInteractions;
    };

    const wrapperFillingForm = (analyticEvent: string | null) => {
        return analyticEvent ? WebAnalyticsEventContentEnum.fillingForm : label;
    };

    const getVerificationTextEmail = () => {
        return isEmailField ? wrapperConfirmed(isValidEmailField) : wrapperConfirmed(isConfirmed);
    };

    const getVerificationActionGroupEmail = () => {
        return isEmailField ? wrapperInteractions(isValidEmailField) : wrapperInteractions(isConfirmed);
    };

    const getVerificationTextPhone = () => {
        if (!isEmailField && isConfirmed) {
            return wrapperConfirmed(isValidPhoneField);
        } else {
            return WebAnalyticsEventActionEnum.rejected;
        }
    };

    const getVerificationActionGroupPhone = () => {
        if (!isEmailField && isConfirmed) {
            return wrapperInteractions(isValidPhoneField);
        } else {
            return WebAnalyticsActionGroupEnum.nonInteractions;
        }
    };

    const getVerificationRequiredField = () => {
        if (isConfirmed) {
            return label;
        } else {
            return isRequiredField ? FEEDBACK_ERRORS_DATA.warningText : FEEDBACK_ERRORS_DATA.emptyField;
        }
    };

    const getVerificationValidEmail = () => {
        if (isConfirmed) {
            return isValidEmailField ? label : FEEDBACK_ERRORS_DATA.validateEmail;
        } else {
            return FEEDBACK_ERRORS_DATA.warningText;
        }
    };

    const getVerificationValidPhone = () => {
        if (!isEmailField && isConfirmed) {
            return isValidPhoneField ? label : FEEDBACK_ERRORS_DATA.validatePhone;
        } else {
            return FEEDBACK_ERRORS_DATA.emptyField;
        }
    };

    const getVerificationEmailField = () => {
        if (isEmailField) {
            return isValidEmailField ? wrapperFillingForm(isConfirmed) : label;
        } else {
            return wrapperFillingForm(isConfirmed);
        }
    };

    const getVerificationPhoneField = () => {
        return isValidPhoneField ? wrapperFillingForm(isConfirmed) : label;
    };

    GtmService.send(
        getAnalyticsEventData({
            event: WebAnalyticsEventEnum.vntForm,
            eventCategory: WebAnalyticsEventCategoryEnum.appForm,
            eventAction: isPhoneField ? getVerificationTextPhone() : getVerificationTextEmail(),
            eventLabel: transliterate(
                isPhoneField
                    ? getVerificationValidPhone()
                    : isEmailField
                        ? getVerificationValidEmail()
                        : getVerificationRequiredField(),
            ),
            eventContent: transliterate(isPhoneField ? getVerificationPhoneField() : getVerificationEmailField()),
            buttonLocation: WebAnalyticsButtonLocationEnum.popup,
            actionGroup: isPhoneField ? getVerificationActionGroupPhone() : getVerificationActionGroupEmail(),
        }),
    );
};

export const sendingSuccessAnalytics = () => {
    GtmService.send(
        getAnalyticsEventData({
            event: WebAnalyticsEventEnum.vntForm,
            eventCategory: WebAnalyticsEventCategoryEnum.appForm,
            eventAction: WebAnalyticsEventActionEnum.confirmed,
            eventLabel: 'uspeh_otpravki_zayavki',
            actionGroup: WebAnalyticsActionGroupEnum.conversions,
        }),
    );
};

export const sendErrorAnalytics = () => {
    GtmService.send(
        getAnalyticsEventData({
            event: WebAnalyticsEventEnum.vntForm,
            eventCategory: WebAnalyticsEventCategoryEnum.appForm,
            eventAction: WebAnalyticsEventActionEnum.rejected,
            eventLabel: 'oshibka_otpravki_zayavki',
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
        }),
    );
};