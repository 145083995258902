import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { ABOUT_PRODUCTS } from '~/src/params';
import { SliderSkeleton } from '~/src/components/skeletons/slider-skeleton/slider-skeleton';
import { NoSSR } from '~/src/helpers/noSSR.helper';
import type { ProductsListItemInterface } from '~/src/repositories/products/products.repository';
import * as Styled from './products-carousel-slide.styles';

interface CarouselSlideProps {
    data?: ProductsListItemInterface;
    width: number;
    id: string;
}

export const ProductsCarouselSlide: FC<CarouselSlideProps> = ({ data, width, id }) => {
    const router = useRouter();
    const [isLongTitle, setIsLongTitle] = useState(false);
    const ref: MutableRefObject<HTMLSpanElement | null> = useRef(null);

    const oneLineHeight = 30;
    const slideMargin = 20;
    const zeroLength = 2;

    useEffect(() => {
        const height = ref.current?.getBoundingClientRect().height;
        setIsLongTitle(Boolean(height && height > oneLineHeight));
    }, [ref.current, data]);

    const getIdWithZero = (id: string) => {
        const leadingZero = '0';
        return id.padStart(zeroLength, leadingZero);
    };

    const handleGoAgencyProducts = () => {
        router.push({ pathname: `${ABOUT_PRODUCTS}` }).then();
    };

    return data ? (
        <Styled.CarouselSlide onClick={handleGoAgencyProducts}>
            <Styled.Content>
                <Styled.Title ref={ref}>{data?.name}</Styled.Title>
                <Styled.Description
                    isLongTitle={isLongTitle}
                    dangerouslySetInnerHTML={{ __html: data?.description || '' }}
                />
            </Styled.Content>
            <Styled.ProductId>{getIdWithZero(id)}</Styled.ProductId>
        </Styled.CarouselSlide>
    ) : (
        <NoSSR>
            <SliderSkeleton width={width - slideMargin} />
        </NoSSR>
    );
};
