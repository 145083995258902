import styled from 'styled-components';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { rem } from '~/src/helpers/rem-converter.helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { COLORS } from '~/src/styles/tokens/colors';

export const TextareaWrapper = styled.div`
    width: 100%;
    flex-direction: column;
    gap: ${rem(4)};
`;

export const Textarea = styled.textarea<{ hasError?: boolean; height: number | undefined }>`
    width: 100%;
    height: ${(p) => p.height ? rem(p.height) : rem(72)};
    border: 2px solid ${(p) => (p.hasError ? COLORS.textNegative : COLORS.controlsInactive)};
    border-radius: ${BORDER_RADIUS.normal8};
    background-color: ${COLORS.backgroundLightSecondary};
    ${FONTS.compact.regular.size17};
    padding: ${rem(14)} ${rem(34)} ${rem(14)} ${rem(12)};
    color: ${COLORS.textSecondary};
    box-sizing: border-box;
    resize: none;

    &:hover {
        border-color: ${COLORS.textLightPrimary};
    }
    
    &:focus {
        border-color: ${COLORS.textPrimaryLink};
    }
    
    &:disabled {
        background-color: ${COLORS.backgroundDisabled};
        cursor: default;
        pointer-events: none;
    }
`;

export const TextareaValueCounter = styled.span`
    color: ${COLORS.textSecondary};
    ${FONTS.sans.regular.size12};
    margin-top: ${rem(4)};
`;

export const Label = styled.label<{ hasError?: boolean }>`
    height: ${rem(20)};
    align-items: flex-end;
    ${FONTS.sans.regular.size14};
    color: ${(p) => (p.hasError ? COLORS.textNegative : COLORS.textSecondary)};
    cursor: default;
`;

export const Star = styled.span`
    align-self: flex-start;
    padding-left: ${rem(4)};
    color: ${COLORS.controlsPrimaryActive};
`;
