import * as React from 'react';
import { SVGProps } from 'react';

export const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
    <svg width={18} height={19} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M1 17a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2H1ZM8 12.033V1a1 1 0 0 1 2 0v11.035c.172-.214.371-.466.61-.768.504-.64.905-1.15 1.222-1.572.302-.403.63-.695 1.169-.695.228 0 .47.108.645.292.28.295.385.788-.027 1.35-.38.52-.86 1.129-1.44 1.864-1.107 1.404-1.66 2.106-2.336 2.348a2.5 2.5 0 0 1-1.685 0c-.676-.242-1.23-.944-2.337-2.348-.58-.735-1.06-1.344-1.44-1.863-.41-.563-.306-1.056-.026-1.351A.915.915 0 0 1 5 9c.539 0 .866.292 1.168.695.318.423.72.932 1.224 1.572.237.301.436.553.608.766Z"
            fill={props.fill || '#007CFF'}
        />
    </svg>
);
