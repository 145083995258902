import React, { FC } from 'react';
import { sendLinkAnalytics } from '~/src/components/common-components/how-it-works-step/how-it-works-step.analytics';
import { StepInterface } from '~/src/components/blocks/main/how-it-works/how-it-works.constants';
import * as Styled from './how-it-works-step.styles';

interface HowItWorksStepProps {
    stepData: StepInterface;
}

export const HowItWorksStep: FC<HowItWorksStepProps> = ({ stepData }) => {

    const onLinkClick = (analyticsText?: string) => {
        analyticsText && sendLinkAnalytics(analyticsText);
    };

    return (
        <Styled.HowItWorksStep>
            <Styled.StepMark>
                <Styled.StepNumber>{stepData.id}</Styled.StepNumber>
            </Styled.StepMark>
            <Styled.Text>
                {stepData.linkTo && stepData.linkText && (
                    <Styled.CustomLink
                        href={stepData.linkTo}
                        rel={'noopener noreferer'}
                        onClick={() => onLinkClick(stepData.analyticsText)}
                    >
                        {stepData.linkText}
                    </Styled.CustomLink>
                )}
                {stepData.description}
            </Styled.Text>
        </Styled.HowItWorksStep>
    );
};
