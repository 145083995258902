import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import {
    MAX_1023,
    MAX_1279,
    MAX_1439,
    MAX_1679,
    MAX_1919,
    MAX_567,
    MAX_767,
    MIN_1920,
} from '~/src/styles/tokens/break-points';

export const Advantages = styled.section`
    width: 100%;
    padding-top: ${rem(120)};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding-top: ${rem(80)};
        `,
    )};
`;

export const Title = styled.h2`
    width: 60%;
    ${FONTS.wide.medium.size44};
    text-transform: uppercase;

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.wide.medium.size58};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            width: 50%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            width: 55%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1439 },
        css`
            width: 60%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            width: 70%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size28};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: unset;
        `,
    )};
`;

export const AdvantagesList = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${rem(32)};
    padding-top: ${rem(52)};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            padding-top: ${rem(60)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            display: flex;
            flex-direction: column;
            padding-top: ${rem(32)};
        `,
    )};
`;

export const Advantage = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: ${rem(24)};
    ${FONTS.compact.regular.size20};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.compact.regular.size24};
            gap: ${rem(32)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            align-items: center;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            ${FONTS.compact.regular.size17};
        `,
    )};
`;

export const ImageWrap = styled.div<{ isMobile: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    width: ${rem(84)};
    height: ${rem(83)};
    transform: scale(${(p) => (p.isMobile ? 0.7 : 1)});
`;
