import { useMutation, useQueryClient } from 'react-query';
import { Dispatch, SetStateAction } from 'react';
import { captureException } from '@sentry/nextjs';
import { FEEDBACK_ERRORS_DATA } from '~/src/components/common-components/feedback-form/feedback-form.constants';
import { BAD_REQUEST_CODE, SUCCESS_CODE } from '~/src/models/constants/status-codes';
import { FeedbackPayloadInterface } from '~/src/repositories/feedback-form/feedback-form.repository';
import { FeedbackFormService } from '~/src/services/feedback-form.service';
import {
    sendErrorAnalytics,
    sendingSuccessAnalytics,
} from '~/src/components/common-components/feedback-form/feedback-form.analytics';
import type { ExtendedAxiosResponse, InternalDataFieldInterface } from '~/src/models/types/axios-response.types';
import type { ChipsDataInterface } from '~/src/models/types/chips.types';

const QUERY_KEY = 'post feedbackForm';

export const useFetchFeedbackForm = (
    setChipsData: Dispatch<SetStateAction<ChipsDataInterface>>,
    setIsSuccessSendFeedbackForm: Dispatch<SetStateAction<boolean>>,
) => {
    const queryClient = useQueryClient();
    const errorSendForm = FEEDBACK_ERRORS_DATA.errorSendForm;
    const {
        mutateAsync: sendFeedbackForm,
        isLoading,
    } = useMutation(QUERY_KEY, (data: FeedbackPayloadInterface) => FeedbackFormService.postFeedbackForm(data), {
        onSuccess: (data?: ExtendedAxiosResponse<InternalDataFieldInterface>) => {
            if (data?.response?.status === SUCCESS_CODE || data?.status === SUCCESS_CODE) {
                setIsSuccessSendFeedbackForm(true);
                sendingSuccessAnalytics();
                return;
            }
            setChipsData({ error: errorSendForm, show: true });
        },
        onError: (error: ExtendedAxiosResponse<InternalDataFieldInterface>) => {
            // eslint-disable-next-line no-console
            console.log(QUERY_KEY, error.response);

            if (error?.response?.status === BAD_REQUEST_CODE) {
                let chipsDataError = 'Ошибка. Попробуйте позже';
                if (error?.response?.data) {
                    const { inputFieldErrors, message } = error.response.data;
                    const [firstFieldError] = Object.values(inputFieldErrors || {});
                    chipsDataError = firstFieldError || message || chipsDataError;
                }
                setChipsData({ error: chipsDataError, show: true });
                sendErrorAnalytics();
                return;
            }

            captureException(error);
            setChipsData({ error: errorSendForm, show: true });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries(QUERY_KEY);
        },
    });

    return { sendFeedbackForm, isLoading };
};
