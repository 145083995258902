import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';
import {
    sendTextAnalytics,
    sendTitleAnalytics,
} from '~/src/components/common-components/faq-accordion/faq-accordion.analytics';
import { openWindow } from '~/src/helpers/open-window.helper';
import { SvgArrowDownBig } from '~/src/components/svg-components/arrow-down-big';
import * as Styled from './faq-accordion.styles';

interface FaqAccordionProps {
    id: number;
    question: string;
    answer: ReactNode;
    offerUrl?: string;
}

export const Accordion: FC<FaqAccordionProps> = ({ question, answer, id, offerUrl }): JSX.Element => {
    const [closed, setClosed] = useState(true);

    const onClickTitle = () => {
        closed && sendTitleAnalytics(question);
        setClosed((prev) => !prev);
    };

    const onClickText = (event: SyntheticEvent<HTMLElement>) => {
        if (event.target instanceof HTMLAnchorElement) {
            const linkText = event.target.innerHTML;
            sendTextAnalytics(linkText, question);
            if (!event.target.href) {
                event.preventDefault();
                offerUrl && openWindow(offerUrl);
            }
        }
    };

    return (
        <Styled.FaqAccordion>
            <Styled.Input type="checkbox" defaultChecked={true} name="accordion" id={id.toString()} />
            <Styled.Title htmlFor={id.toString()} onClick={onClickTitle}>
                <Styled.Heading>{question}</Styled.Heading>
                <Styled.Triangle>
                    <SvgArrowDownBig />
                </Styled.Triangle>
            </Styled.Title>
            <Styled.Text onClick={onClickText}>{answer}</Styled.Text>
        </Styled.FaqAccordion>
    );
};
