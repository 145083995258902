import styled, { css } from 'styled-components';
import Image from 'next/image';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { MAX_1023, MAX_1679, MAX_1919, MAX_567, MAX_767, MIN_1440, MIN_1920 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import MainButton from '~/src/components/form-components/main-button/main-button';

export const MtsAgentsAboutApi = styled.section`
    width: 100%;
    margin-top: ${rem(80)};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            margin-top: ${rem(60)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            margin-top: ${rem(40)};
        `,
    )};
`;

export const Banner = styled.div`
    display: grid;
    grid-template-columns: ${rem(400)} 1fr;
    gap: ${rem(24)};
    justify-items: end;
    width: 100%;
    overflow: hidden;
    padding: ${rem(52)} ${rem(60)} 0;
    background: ${COLORS.gradientRedPurpleViolet};
    border-radius: ${BORDER_RADIUS.biggest20};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            padding: ${rem(52)} ${rem(60)} 0 ${rem(104)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            padding: ${rem(52)} ${rem(60)} 0 ${rem(80)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            padding: ${rem(52)} ${rem(60)} 0 ${rem(60)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            grid-template-columns: ${rem(252)} 1fr;
            padding: ${rem(24)} ${rem(24)} 0;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            display: flex;
            flex-direction: column;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            padding: ${rem(16)} ${rem(16)} 0;
        `,
    )};
`;

export const Content = styled.div<{ isShowButton?: boolean; }>`
    width: 100%;
    color: ${COLORS.textInverted};
    margin-top: ${(p) => (p.isShowButton ? 0 : rem(52))};
    margin-bottom: ${rem(52)};
    height: fit-content;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: 0;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            margin-bottom: ${rem(24)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            text-align: center;
            justify-content: center;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            text-align: center;
        `,
    )};
`;

export const Title = styled.h2`
    ${FONTS.wide.medium.size32};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size20};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            margin: 0 auto;
        `,
    )};
`;

export const Subtitle = styled.span`
    margin-top: ${rem(20)};
    ${FONTS.compact.regular.size20};
    line-height: ${rem(28)};

    ${mediaQuery(
        { minWidth: MIN_1440 },
        css`
            max-width: ${rem(390)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.compact.regular.size14};
        `,
    )};
`;

export const ButtonsArea = styled.div`
    display: flex;
    padding-top: ${rem(32)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            align-items: flex-start;
            justify-content: center;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            flex-direction: column;
            align-items: center;
            width: 100%;
        `,
    )};
`;

export const CustomButton = styled(MainButton)`
    min-width: ${rem(251)};
    width: unset;
    margin-top: ${rem(32)};
    ${FONTS.sans.medium.size17};
    cursor: pointer;

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: 100%;
            max-width: 100%;
        `,
    )};
`;

export const DocumentationLink = styled.a`
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding-left: 0;
        `,
    )};
`;

export const DownloadText = styled.span`
    padding-left: ${rem(10)};
    ${FONTS.compact.medium.size20};
    color: ${COLORS.textPrimaryLinkDark};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.compact.medium.size17};
        `,
    )};
`;

export const ImageContainer = styled.div`
    position: relative;
    width: ${rem(624)};
    margin-right: ${rem(44)};

    img {
        border-radius: ${BORDER_RADIUS.big16} ${BORDER_RADIUS.big16} 0 0;
    }

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            height: unset;
            width: 100%;
            padding-bottom: 54%;
        `,
    )};
`;

export const TitleWrap = styled.div`
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            flex-direction: column;
        `,
    )};
`;

export const ImageApi = styled(Image)`
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            height: fit-content;
            position: absolute;
            bottom: 0;
            object-fit: cover;
            object-position: top;
        `,
    )};
`;
