import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_567 } from '~/src/styles/tokens/break-points';

export const ChipsNotification = styled.div<{ shift: number }>`
    position: fixed;
    top: 50%;
    left: calc(50% - ${p => rem(p.shift)});
    height: fit-content;
    justify-content: flex-start;
    align-items: center;
    padding: ${rem(12)};
    z-index: ${Z_INDEX.level5};
    background-color: ${COLORS.backgroundInverted};
    border-radius: ${BORDER_RADIUS.normal8};

    ${(p) => p.shift && css`
        ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            left: calc((50% - ${rem(p.shift)}) / 2);
        `,
    )}
  `}
`;

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
`;

export const TextChips = styled.div`
	justify-content: center;
	align-items: center;
    padding-left: ${rem(5)};
    color: ${COLORS.textInverted};
    ${FONTS.sans.regular.size17};
`;
