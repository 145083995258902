import React, { FC, useEffect, useRef, useState } from 'react';
import { SvgCrossChips } from '~/src/components/svg-components/svg-cross-chips';
import * as Styled from './chips-notification.styles';

interface ChipsProps {
    chipsText?: string;
    onChipsDisappear: () => void;
    className?: string;
}

export const ChipsNotification: FC<ChipsProps> = ({ chipsText, onChipsDisappear, className }) => {
    const [width, setWidth] = useState(0);
    const chipsRef = useRef<HTMLDivElement | null>(null);
    const hideTime = 4000;

    useEffect(() => {
        const timerChipsOnOff = setTimeout(() => {
            onChipsDisappear();
        }, hideTime);

        return () => {
            clearTimeout(timerChipsOnOff);
        };
    }, []);

    useEffect(() => {
        chipsRef.current && setWidth(chipsRef.current.getBoundingClientRect().width);
    }, [chipsRef.current]);

    return (
        <Styled.ChipsNotification
            className={className}
            ref={chipsRef}
            shift={Math.floor(width / 2)}
            data-testid={'chips'}
        >
            <Styled.Wrapper>
                <SvgCrossChips data-testid={'cross-chips'} />
                <Styled.TextChips data-testid={'chips-text'}>{chipsText}</Styled.TextChips>
            </Styled.Wrapper>
        </Styled.ChipsNotification>
    );
};
