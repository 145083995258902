import React from 'react';

export const SvgLock = ({ className }: { className?: string }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={14}
        fill="none"
        viewBox="0 0 12 14"
        className={className}
    >
        <path
            fill="#969FA8"
            fillRule="evenodd"
            d="M10.984 5.125a3.026 3.026 0 0 0-.81-.604 49.245 49.245 0 0 0-.007-.128c-.087-1.647-.13-2.47-.986-3.365a4.763 4.763 0 0 0-.233-.22C8.011 0 7.34 0 6 0 4.66 0 3.99 0 3.052.808a4.758 4.758 0 0 0-.233.22c-.856.894-.9 1.718-.986 3.365l-.006.128a3.026 3.026 0 0 0-.81.604C.12 6.036.084 6.845.011 8.462a12.007 12.007 0 0 0 0 1.076c.073 1.617.11 2.426 1.004 3.337.895.91 1.791.967 3.584 1.079.457.029.928.046 1.4.046.472 0 .943-.017 1.4-.046 1.793-.112 2.689-.168 3.584-1.08.895-.91.931-1.719 1.004-3.336a12.046 12.046 0 0 0 0-1.076c-.073-1.617-.11-2.426-1.004-3.337Zm-7.082-3.06c-.388.407-.47.65-.553 2.075.366-.039.777-.064 1.25-.094A22.5 22.5 0 0 1 6 4c.472 0 .943.017 1.4.046.474.03.886.055 1.25.094-.082-1.425-.164-1.668-.552-2.074-.225-.223-.482-.43-.8-.501C7.042 1.508 6.722 1.5 6 1.5c-.721 0-1.041.008-1.298.065-.315.07-.579.276-.8.502v-.001ZM7 9a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z"
            clipRule="evenodd"
        />
    </svg>
);
