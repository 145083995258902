import React, { FC } from 'react';

export interface SuccessIconProps {
    className?: string;
    analyticsref?: (node: Element | null) => void;
    [key: string]: unknown;
}

export const SvgSuccess: FC<SuccessIconProps> = (props) => {
    const { className, analyticsref } = props;
    return (
        <svg
            ref={analyticsref}
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            fill="none"
            viewBox="0 0 18 18"
            className={className}
            {...props}
        >
            <path
                fill="#26CD58"
                fillRule="evenodd"
                d="M1.686 1.686C.373 2.999.276 4.306.08 6.923.03 7.6 0 8.3 0 9s.03 1.399.08 2.077c.196 2.616.293 3.924 1.606 5.237 1.313 1.313 2.62 1.41 5.237 1.605C7.6 17.969 8.3 18 9 18s1.399-.03 2.077-.081c2.616-.195 3.924-.292 5.237-1.605 1.313-1.313 1.41-2.62 1.605-5.237.05-.678.08-1.377.08-2.077s-.03-1.399-.08-2.077c-.195-2.616-.292-3.924-1.605-5.237C15 .373 13.693.276 11.077.081A28.108 28.108 0 0 0 9 0c-.7 0-1.4.03-2.077.081-2.616.195-3.924.292-5.237 1.605Zm11.52 5.521a1 1 0 0 0-1.413-1.414L8 9.586 6.707 8.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4.5-4.5Z"
                clipRule="evenodd"
            />
        </svg>
    );
};
