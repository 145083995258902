import { GtmService } from '~/src/services/web-analytics.service';
import { getAnalyticsEventData } from '~/src/helpers/web-analytics-events-data.helper';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
} from '~/src/models/enums/web-analytics.enum';
import { transliterate } from '~/src/helpers/transliterate.helper';

export const sendButtonAnalytics = (label: string) => {
    const labelTranslate = transliterate(label);
    GtmService.send(
        getAnalyticsEventData({
            eventAction: WebAnalyticsEventActionEnum.buttonClick,
            eventLabel: labelTranslate,
            eventContent: WebAnalyticsEventContentEnum.topBlock,
            event: WebAnalyticsEventEnum.vntEvent,
            eventCategory: WebAnalyticsEventCategoryEnum.mainPage,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};

export const sendDownloadLinkAnalytics = () => {
    GtmService.send(
        getAnalyticsEventData({
            eventAction: WebAnalyticsEventActionEnum.linkClick,
            eventLabel: 'usloviya_programmy',
            eventContent: WebAnalyticsEventContentEnum.topBlock,
            event: WebAnalyticsEventEnum.vntEvent,
            eventCategory: WebAnalyticsEventCategoryEnum.mainPage,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};
