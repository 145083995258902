import React, { FocusEvent, ChangeEvent, FC, useState, useEffect } from 'react';
import {
    FEEDBACK_APP_SENT_DATA,
    FEEDBACK_ERRORS_DATA,
    FEEDBACK_TEXT_DATA,
    INITIAL_DATA_ERROR,
    INITIAL_DATA_FOCUS,
    INITIAL_DATA_TEXT,
    MAX_MESSAGE_LENGTH,
    MAX_PHONE_LENGTH,
    FieldFormEnum,
} from '~/src/components/common-components/feedback-form/feedback-form.constants';
import { useFetchFeedbackForm } from '~/src/repositories/feedback-form/hook/use-fetch-feedback-form.hook';
import { validateEmail } from '~/src/helpers/inputs-validators';
import Checkbox from '~/src/components/form-components/checkbox/checkbox';
import Textbox from '~/src/components/form-components/textbox/textbox';
import Textarea from '~/src/components/form-components/textarea/textarea';
import { ChipsNotification } from '~/src/components/common-components/chips-notification/chips-notification';
import { AppSentNotification } from '~/src/components/common-components/app-sent-notification/app-sent-notification';
import { CheckboxSizeEnum } from '~/src/components/form-components/checkbox/checkbox.types';
import { SvgClose } from '~/src/components/svg-components/close';
import { SvgSuccess } from '~/src/components/svg-components/success';
import {
    sendCustomButtonClickAnalytics,
    sendCustomCheckboxClickAnalytics,
    sendFieldsFillingAnalytics,
    sendErrorAnalytics,
} from '~/src/components/common-components/feedback-form/feedback-form.analytics';
import { getMappingData } from './feedback-form.utils';
import type {
    FeedbackFormProps,
    FormEvent,
} from '~/src/components/common-components/feedback-form/feedback-form.types';
import type { ChipsDataInterface } from '~/src/models/types/chips.types';
import * as Styled from './feedback-form.styles';

export const FeedbackForm: FC<FeedbackFormProps> = ({ isOpen, onClose, setShowFeedbackForm }) => {
    const [isSuccessSendFeedbackForm, setIsSuccessSendFeedbackForm] = useState(false);
    const [chipsData, setChipsData] = useState<ChipsDataInterface>({ show: false, error: '' });
    const { sendFeedbackForm, isLoading } = useFetchFeedbackForm(setChipsData, setIsSuccessSendFeedbackForm);
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        setChecked((prev) => !prev);
        !checked && sendCustomCheckboxClickAnalytics();
    };
    const [dataError, setDataError] = useState(INITIAL_DATA_ERROR);
    const [requiredFieldsCorrect, setRequiredFieldsCorrect] = useState(false);
    const [dataTexts, setDataTexts] = useState<{[key: string]: null | string}>(INITIAL_DATA_TEXT);

    const [isValidFieldData, setIsValidFieldData] = useState({ company: false, email: false, comment: false });
    const [isFocusData, setIsFocusData] = useState(INITIAL_DATA_FOCUS);

    useEffect(() => {
        const _requiredFieldsCorrect = isValidFieldData.company && isValidFieldData.email && isValidFieldData.comment;
        setRequiredFieldsCorrect(_requiredFieldsCorrect);
    }, [isValidFieldData.comment, isValidFieldData.company, isValidFieldData.email]);

    useEffect(() => {
        setIsValidFieldData(() => ({
            company: Boolean(dataTexts.company && !dataError.company),
            email: Boolean(dataTexts.email && !dataError.email),
            comment: Boolean(dataTexts.comment && !dataError.comment),
        }));
    }, [dataTexts, dataError]);

    const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    useEffect(() => {
        isSuccessSendFeedbackForm && setShowFeedbackForm(false);
    }, [isSuccessSendFeedbackForm]);

    useEffect(() => {
        if (!isOpen) {
            setDataError(INITIAL_DATA_ERROR);
            setDataTexts(INITIAL_DATA_TEXT);
            setChecked(false);
        }
    }, [isOpen]);

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name as keyof typeof dataTexts;
        let value = event.target.value;

        if (name === FieldFormEnum.phone) {
            const regExpPhone = /^[0-9 +]+$/;
            const phoneMask = '+7';
            if (!regExpPhone.test(value)) {
                return;
            }
            value = (event.target.value).trim();
            if (value && !value.includes('+')) {
                value = phoneMask + value;
            }
        }

        if (name === FieldFormEnum.fullName) {
            const regExpFullName = /[0-9]/g;
            value = (event.target.value).replace(regExpFullName, '');
        }

        setDataTexts((prevState) => ({
            ...prevState,
            [name]: value.trimStart(),
        }));

        setDataError((prevState) => ({
            ...prevState,
            [name]: '',
        }));

        if (name === FieldFormEnum.email && !validateEmail(String(value).toLowerCase())) {
            setDataError((prevState) => ({
                ...prevState,
                [name]: FEEDBACK_ERRORS_DATA.validateEmail,
            }));
        }
    };

    const onBlurHandler = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name as FieldFormEnum;
        event.target.required && !dataTexts[name] && setDataError((prevState) => ({
            ...prevState,
            [name]: FEEDBACK_ERRORS_DATA.warningText,
        }));

        const label = event.target.title;
        const isConfirmed = dataTexts[name];
        const isEmailField = name === FieldFormEnum.email;
        const isRequiredField = event.target.required;
        const isValidEmailField = isValidFieldData.email;
        const isPhoneField = name === FieldFormEnum.phone;
        const isValidPhoneField = dataTexts.phone?.length === MAX_PHONE_LENGTH;
        sendFieldsFillingAnalytics(label, isConfirmed, isEmailField,
            isRequiredField, isValidEmailField, isPhoneField, isValidPhoneField);

        setIsFocusData((prev) => ({
            ...prev,
            [name]: false,
        }));
    };

    const onFocusHandler = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name as FieldFormEnum;

        setIsFocusData((prev) => ({
            ...prev,
            [name]: true,
        }));
    };

    const checkErrors = () => {
        return Object.values(dataError).some(String);
    };

    const onCustomButtonClick = () => {
        const label = FEEDBACK_TEXT_DATA.buttonText;
        sendCustomButtonClickAnalytics(label);
    };

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const hasError = checkErrors();

        if (hasError) {
            setChipsData({ error: FEEDBACK_ERRORS_DATA.errorSendForm, show: hasError });
            sendErrorAnalytics();
            return;
        }
        const mappingData = getMappingData(formData);
        await sendFeedbackForm(mappingData);
    };

    const handleFieldClear = (nameField: FieldFormEnum) => {
        setDataTexts((prevState) => ({
            ...prevState,
            [nameField]: '',
        }));
    };

    const getHasErrorForTextarea = () => {
        return typeof dataTexts.comment === 'string' && !dataTexts.comment;
    };

    return (
        <>
            <Styled.FeedbackFormBackground isOpen={isOpen} />
            {isOpen && (
                <Styled.FeedbackFormWrapper onMouseDown={onClose}>
                    <Styled.FeedbackForm
                        onSubmit={submitHandler}
                        onMouseDown={event => event.stopPropagation()}
                    >
                        <Styled.Title>
                            {FEEDBACK_TEXT_DATA.title}
                            <Styled.CloseIconButton onClick={onClose}>
                                <SvgClose />
                            </Styled.CloseIconButton>
                        </Styled.Title>
                        <Styled.Blocks>
                            <Styled.WrapperRow>
                                <Styled.InputBlock>
                                    <Textbox
                                        onClear={() => handleFieldClear(FieldFormEnum.company)}
                                        onValueChange={inputChangeHandler}
                                        onBlurHandler={onBlurHandler}
                                        onFocusHandler={onFocusHandler}
                                        name={FieldFormEnum.company}
                                        value={dataTexts.company || ''}
                                        required
                                        validated
                                        placeholder={'Введите'}
                                        label={FEEDBACK_TEXT_DATA.nameCompany}
                                        error={dataTexts.company ? '' : dataError.company}
                                    />
                                </Styled.InputBlock>
                                <Styled.InputBlock>
                                    <Textbox
                                        onClear={() => handleFieldClear(FieldFormEnum.fullName)}
                                        onValueChange={inputChangeHandler}
                                        onBlurHandler={onBlurHandler}
                                        onFocusHandler={onFocusHandler}
                                        name={FieldFormEnum.fullName}
                                        value={dataTexts.fullName || ''}
                                        placeholder={'ФИО'}
                                        label={FEEDBACK_TEXT_DATA.fullName}
                                    />
                                </Styled.InputBlock>
                            </Styled.WrapperRow>
                            <Styled.WrapperRow>
                                <Styled.InputBlock>
                                    <Textbox
                                        onClear={() => handleFieldClear(FieldFormEnum.email)}
                                        onValueChange={inputChangeHandler}
                                        onBlurHandler={onBlurHandler}
                                        onFocusHandler={onFocusHandler}
                                        name={FieldFormEnum.email}
                                        value={dataTexts.email || ''}
                                        placeholder={'Email'}
                                        validated
                                        required
                                        label={FEEDBACK_TEXT_DATA.email}
                                        error={dataError.email}
                                    />
                                </Styled.InputBlock>
                                <Styled.InputBlock>
                                    <Textbox
                                        onClear={() => handleFieldClear(FieldFormEnum.phone)}
                                        onValueChange={inputChangeHandler}
                                        onBlurHandler={onBlurHandler}
                                        onFocusHandler={onFocusHandler}
                                        name={FieldFormEnum.phone}
                                        value={dataTexts.phone || ''}
                                        placeholder={'+7 (9__) ___-__-__'}
                                        label={FEEDBACK_TEXT_DATA.phone}
                                        maxLength={MAX_PHONE_LENGTH}
                                    />
                                </Styled.InputBlock>
                            </Styled.WrapperRow>
                        </Styled.Blocks>
                        <Styled.TextareaBlock>
                            {isValidFieldData.comment && !isFocusData.comment &&
                                <Styled.IconContainer withInBigBlock={true}>
                                    <SvgSuccess />
                                </Styled.IconContainer>
                            }
                            <Textarea
                                hasError={getHasErrorForTextarea()}
                                onTextChange={inputChangeHandler}
                                onBlurHandler={onBlurHandler}
                                onFocusHandler={onFocusHandler}
                                label={FEEDBACK_TEXT_DATA.yourQuestion}
                                name={FieldFormEnum.comment}
                                value={dataTexts.comment || ''}
                                placeholder={'Введите сообщение'}
                                required={true}
                                maxLength={MAX_MESSAGE_LENGTH}
                            />
                            {(dataTexts.comment && isFocusData.comment)
                                && <Styled.CleaningButton withInBigBlock={true}
                                    type={'button'}
                                    title={'Очистить сообщение'}
                                    onMouseDown={() => handleFieldClear(FieldFormEnum.comment)}
                                >
                                    <SvgClose />
                                </Styled.CleaningButton>}
                            <Styled.WrapperWarningText>
                                <Styled.WarningText notNecessary={true} isShow={true}>
                                    {FEEDBACK_TEXT_DATA.limitCharacter}
                                </Styled.WarningText>
                                <Styled.WarningText isShow={!!dataError.comment}>
                                    {dataError.comment}
                                </Styled.WarningText>
                            </Styled.WrapperWarningText>
                        </Styled.TextareaBlock>
                        <Styled.Agreement>
                            <Checkbox
                                checked={checked && requiredFieldsCorrect}
                                onChange={handleChange}
                                disabled={!requiredFieldsCorrect}
                                size={CheckboxSizeEnum.LARGE}
                                LabelComponent={
                                    <Styled.TextConsent>
                                        {FEEDBACK_TEXT_DATA.iAgree}
                                        <Styled.LinkConsent
                                            href={FEEDBACK_TEXT_DATA.linkIAgree}
                                            rel={'noopener, noreferrer'}
                                            target={'_blank'}
                                        >
                                            {FEEDBACK_TEXT_DATA.textIAgree}
                                        </Styled.LinkConsent>
                                    </Styled.TextConsent>
                                }
                            />
                        </Styled.Agreement>
                        <Styled.CustomButton
                            isLoading={isLoading}
                            onClick={onCustomButtonClick}
                            isDisabled={!checked || !requiredFieldsCorrect}
                        >
                            {FEEDBACK_TEXT_DATA.buttonText}
                        </Styled.CustomButton>
                    </Styled.FeedbackForm>
                </Styled.FeedbackFormWrapper>
            )}
            {isSuccessSendFeedbackForm && (
                <AppSentNotification
                    title={FEEDBACK_APP_SENT_DATA.title}
                    description={FEEDBACK_APP_SENT_DATA.description}
                    onAppSentDisappear={() => setIsSuccessSendFeedbackForm(false)}
                />
            )}
            {chipsData.show && (
                <ChipsNotification
                    chipsText={chipsData.error as string}
                    onChipsDisappear={() => setChipsData((prev) => ({ ...prev, show: false }))}
                />
            )}
        </>
    );
};