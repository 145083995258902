import React, { FC, ReactElement, ReactNode } from 'react';
import { authBigTitle } from '~/src/components/blocks/main/caption-block-content/caption-block-content.constants';
import { connectionStagesStatusData } from './legal-status-block.constants';
import { CounterpartyConnectStatusEnum } from '~/src/models/enums/counterparty-connect-status.enum';
import { DefaultStatusContent } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/default-status-content/default-status-content';
import { BigTitle } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/big-title/big-title';
import * as Styled from './legal-status-block.styles';

interface LegalStatusBlockProps {
    organizationConnectionStatus: CounterpartyConnectStatusEnum;
    isConnectedStatus: boolean;
    getContentText: (getText: (str: string, isMobile?: boolean) => string | ReactElement) => string | ReactElement;
    componentButtons: ReactNode;
}

export const LegalStatusBlock: FC<LegalStatusBlockProps> = ({
    organizationConnectionStatus,
    isConnectedStatus,
    getContentText,
    componentButtons,
}) => {
    const connectionStatusData = connectionStagesStatusData[organizationConnectionStatus];
    const isCanConnectStatus = organizationConnectionStatus === CounterpartyConnectStatusEnum.CAN_CONNECT;

    if (connectionStatusData) {
        const { withIcon, title, getText } = connectionStatusData;

        return (
            <>
                <BigTitle marginTop={6} bigTitleText={authBigTitle} />
                <Styled.AuthContentWrapper isConnectedStatus={isConnectedStatus}>
                    <Styled.AuthContentSubTitleWrapper>
                        {withIcon && <Styled.InfoIcon />}
                        {title && <Styled.AuthContentSubTitle>{title}</Styled.AuthContentSubTitle>}
                    </Styled.AuthContentSubTitleWrapper>
                    {getText && (
                        <Styled.AuthContentTextContainer isConnectedStatus={isConnectedStatus}>
                            {getContentText(getText)}
                        </Styled.AuthContentTextContainer>
                    )}
                    <Styled.ButtonsArea isConnectedStatus={isConnectedStatus} alignCenterOnMobile={isCanConnectStatus}>
                        {componentButtons}
                    </Styled.ButtonsArea>
                </Styled.AuthContentWrapper>
            </>
        );
    }

    return <DefaultStatusContent componentButtons={componentButtons} />;
};
