import { GtmService } from '~/src/services/web-analytics.service';
import { getAnalyticsEventData } from '~/src/helpers/web-analytics-events-data.helper';
import { transliterate } from '~/src/helpers/transliterate.helper';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
} from '~/src/models/enums/web-analytics.enum';

export const sendTitleAnalytics = (question: string) => {
    GtmService.send(
        getAnalyticsEventData({
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: transliterate(question),
            eventContent: WebAnalyticsEventContentEnum.questionsAndAnswers,
            event: WebAnalyticsEventEnum.vntEvent,
            eventCategory: WebAnalyticsEventCategoryEnum.mainPage,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};

export const sendTextAnalytics = (linkText: string, question: string) => {
    GtmService.send(
        getAnalyticsEventData({
            eventAction: WebAnalyticsEventActionEnum.linkClick,
            eventLabel: transliterate(linkText),
            eventContent: WebAnalyticsEventContentEnum.questionsAndAnswers,
            eventContext: transliterate(question),
            event: WebAnalyticsEventEnum.vntEvent,
            eventCategory: WebAnalyticsEventCategoryEnum.mainPage,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};