import React, { FC, useRef } from 'react';
import { useMatchMedia } from '~/src/hooks/use-match-media.hook';
import { Carousel } from '~/src/components/common-components/carousel/carousel';
import { MediaWidthEnum } from '~/src/models/enums/media-width.enum';
import {
    ProductsCarouselSlide,
} from '~/src/components/common-components/products-carousel/products-carousel-slide/products-carousel-slide';
import { onSendSwipeEventAnalytics } from '~/src/components/common-components/products-carousel/products-carousel.analytics';
import { SLIDE_WIDTH } from '~/src/components/common-components/products-carousel/products-carousel.constants';
import type { ProductsListItemInterface } from '~/src/repositories/products/products.repository';
import * as Styled from './products-carousel.styles';

interface ProductsCarouselProps {
    slidesData?: ProductsListItemInterface[] | [];
}

export const ProductsCarousel: FC<ProductsCarouselProps> = ({ slidesData }) => {
    const [isTablet] = useMatchMedia(MediaWidthEnum.tabletWide);
    const [isMobile] = useMatchMedia(MediaWidthEnum.mobile);
    const ref = useRef<HTMLDivElement | null>(null);
    const slideFullWidth = isMobile ? SLIDE_WIDTH.narrow : SLIDE_WIDTH.wide;

    const getDataForSliders = (): undefined[] | ProductsListItemInterface[] => {
        const skeletonSliderCount = 16;
        if (slidesData?.length) {
            return slidesData;
        }

        return [...Array(skeletonSliderCount)];
    };

    return (
        <Styled.Carousel ref={ref}>
            <Carousel
                additionalContent={
                    <Styled.AllProductsLink href="/about-products">
                        Все продукты
                    </Styled.AllProductsLink>
                }
                additionalContentAlign={isTablet ? 'start' : 'end'}
                navigationWrapper={Styled.CarouselNavigationWrapper}
                beforeSlideChange={onSendSwipeEventAnalytics}
                infinite
                withControls
            >
                {getDataForSliders().map((slide, id) => (
                    <ProductsCarouselSlide
                        key={slide?.code || id}
                        data={slide}
                        width={slideFullWidth}
                        id={String(++id)}
                    />
                ))}
            </Carousel>
        </Styled.Carousel>
    );
};
