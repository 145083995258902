import { useState, useEffect, MutableRefObject } from 'react';

export function useHover(ref: MutableRefObject<HTMLDivElement | null>) {
    const [value, setValue] = useState(false);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(() => {
        const current = ref?.current;
        if (current) {
            const current = ref?.current;
            current?.addEventListener('mouseover', handleMouseOver);
            current?.addEventListener('mouseout', handleMouseOut);
        }

        return () => {
            current?.removeEventListener('mouseover', handleMouseOver);
            current?.removeEventListener('mouseout', handleMouseOut);
        };
    }, [ref?.current]);

    return value;
}
