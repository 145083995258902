import React, { FC } from 'react';
import { COLORS } from '~/src/styles/tokens/colors';

interface SvgErrorProps {
    fill?: COLORS;
    analyticsreferror?: (node: Element | null) => void;
}

export const SvgError: FC<SvgErrorProps> = ({ fill = COLORS.accentNegative, analyticsreferror }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" viewBox="0 0 18 18">
        <path
            ref={analyticsreferror}
            fill={fill}
            fillRule="evenodd"
            d="M1.686 16.314C.373 15.001.276 13.694.081 11.077A28.107 28.107 0 0 1 0 9c0-.7.03-1.399.081-2.077.195-2.616.292-3.924 1.605-5.237C2.999.373 4.306.276 6.923.081 7.6.031 8.3 0 9 0s1.399.03 2.077.081c2.616.195 3.924.292 5.237 1.605 1.313 1.313 1.41 2.62 1.605 5.237C17.969 7.6 18 8.3 18 9s-.03 1.399-.081 2.077c-.195 2.616-.292 3.924-1.605 5.237-1.313 1.313-2.62 1.41-5.237 1.605A28.09 28.09 0 0 1 9 18c-.7 0-1.399-.03-2.077-.081-2.616-.195-3.924-.292-5.237-1.605ZM9 10a1 1 0 0 0 1-1V5.002a1 1 0 0 0-2 0V9a1 1 0 0 0 1 1Zm1.248 3.001a1.249 1.249 0 1 0-2.498 0 1.249 1.249 0 0 0 2.498 0Z"
            clipRule="evenodd"
        />
    </svg>
);
