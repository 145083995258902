import axios, { AxiosResponse } from 'axios';
import { getRequestUrlHelper } from '~/src/helpers/get-request-url.helper';
import { apiKeyPostFeedbackForm, defaultUrlPostFeedbackForm } from '~/src/repositories/feedback-form/api-urls';
import type { InternalDataFieldInterface } from '~/src/models/types/axios-response.types';

export interface FeedbackPayloadInterface {
    companyName: string;
    email: string;
    fullName: string;
    phoneNumber: string;
    question: string;
    consentProcessingPersonalData: boolean;
}

export const postFeedbackForm = async (data: FeedbackPayloadInterface, isSSR?: boolean):
    Promise<AxiosResponse<InternalDataFieldInterface> | undefined> => {
    const url = getRequestUrlHelper({ isSSR, apiKey: apiKeyPostFeedbackForm, defaultUrl: defaultUrlPostFeedbackForm });
    return await axios.post(url, data);
};
