import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { CheckboxIcon } from '~/src/components/svg-components/checkbox-icon';

export const LabelWrapper = styled.label<{ disabled: boolean; }>`
    cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};;
    display: inline-flex;
    align-items: center;
`;

export const LabelText = styled.div`
    display: inline-flex;
    align-items: center;
    user-select: none;
    margin-left: ${rem(8)};
`;

export const CheckBoxWrapper = styled.div`
    align-items: center;
`;

export const CheckBox = styled.div`
    position: relative;
`;

export const CustomCheckboxIcon = styled(CheckboxIcon)`
    display: block;
`;

export const HiddenInput = styled.input<{ isDesktop: boolean; disabled: boolean; }>`
    appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 75%;
    height: 75%;
    opacity: 0;
    margin: auto;
    border: 0;
    border-radius: 0;
    cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
    user-select: none;
    z-index: ${Z_INDEX.level1};

    ${p => p.isDesktop && !p.disabled && css`
        &:hover + ${CustomCheckboxIcon} {
            transition: 0.2s transform;
            transform: scale(1.05);
        }

        &:active + ${CustomCheckboxIcon} {
            transition: 0.2s transform;
            transform: scale(1);
        }
    `}
`;
