export const PORTAL_URL =
    'https://support.mts.ru/mts_agents/Kak-zaregistrirovatsya-v-programme-MTS-Agenti/kak-zaregistrirovatsya-v-programme-mts-agenti';

let globalId = 0;

export const FAQ_TEXTS = [
    {
        id: globalId++,
        question: 'Как заключить агентский договор?',
        answer: (
            <>
                Информация по заключению агентского договора размещена на{' '}
                <a target="_blank" rel="noopener noreferer" href={PORTAL_URL}>
                    портале
                </a>
            </>
        ),
    },
    {
        id: globalId++,
        question:
            'Использует ли МТС ЭДО для подписания агентского договора и/или первичной документации (счета, акты, счета фактуры)?',
        answer: 'Да, использует.',
    },
    {
        id: globalId++,
        question: 'Есть ли какие-то обязательства агента перед оператором?',
        answer: (
            <>
                Обязательства между оператором и агентом указаны в <a>договоре оферты</a>
            </>
        ),
    },
    {
        id: globalId++,
        question: 'Какой размер агентского вознаграждения? Когда агент получает вознаграждение?',
        answer: (
            <>
                Размер агентского вознаграждения зависит от продукта и определяется договором оферты. Условия и порядок
                взаиморасчётов определяется параграфом 4 <a>договора оферты</a>
            </>
        ),
    },
    {
        id: globalId++,
        question: 'Как можно узнать о том, что клиент подключился?',
        answer: 'Статус отработки переданного лида указывается в личном кабинете или в службе поддержки партнеров.',
    },
    {
        id: globalId++,
        question: 'Как осуществляется поддержка агентов?',
        answer: 'Поддержка осуществляется по форме обратной связи или службой поддержки партнеров.',
    },
];
