import React, { FC } from 'react';
import { HowItWorks } from '~/src/components/blocks/main/how-it-works/how-it-works';
import { MtsAgentsAboutApi } from '~/src/components/blocks/main/mts-agents-about-api/mts-agents-about-api';
import { PersonalCabinet } from '~/src/components/blocks/main/personal-cabinet/personal-cabinet';
import { RewardBanner } from '~/src/components/blocks/main/reward-banner/reward-banner';
import { ProductsList } from '~/src/components/blocks/main/products-list/products-list';
import { IncomeBanner } from '~/src/components/blocks/main/income-banner/income-banner';
import { CaptionBlock } from '~/src/components/blocks/main/caption-block/caption-block';
import { Advantages } from '~/src/components/blocks/main/advantages/advantages';
import { Faq } from '~/src/components/blocks/main/faq/faq';
import { useSelector } from 'react-redux';
import { selectCurrentOrganizationConnectionStatus } from '~/src/redux/user/user.selectors';
import { CounterpartyConnectStatusEnum } from '~/src/models/enums/counterparty-connect-status.enum';
import type { ProductsListItemInterface } from '~/src/repositories/products/products.repository';
import * as Styled from './main-landing-layout.styles';

interface MainLandingProps {
    productsData: ProductsListItemInterface[] | [];
    offerUrl: string;
}

const MainLandingLayout: FC<MainLandingProps> = ({ productsData, offerUrl }) => {
    const organizationConnectionStatus = useSelector(selectCurrentOrganizationConnectionStatus);
    const isOrganizationConnect = organizationConnectionStatus === CounterpartyConnectStatusEnum.CONNECTED
        || organizationConnectionStatus === CounterpartyConnectStatusEnum.DATA_VALIDATION
        || organizationConnectionStatus === CounterpartyConnectStatusEnum.CAN_CONNECT;

    return (
        <Styled.ContainerLayout>
            <CaptionBlock offerUrl={offerUrl} />
            {!isOrganizationConnect && (
                <>
                    <IncomeBanner />
                    <RewardBanner />
                </>
            )}
            <ProductsList productsData={productsData} isOrganizationConnect={isOrganizationConnect} />
            <HowItWorks offerUrl={offerUrl} />
            <PersonalCabinet />
            <MtsAgentsAboutApi />
            <Advantages />
            <Faq offerUrl={offerUrl} />
        </Styled.ContainerLayout>
    );
};

export default MainLandingLayout;
