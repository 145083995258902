import { useEffect, useState } from 'react';

export const useOrientation = () => {
    const [orientation, setOrientation] = useState('');

    useEffect(() => {
        function handleOrientationChange(event: MediaQueryListEvent) {
            const { matches, media } = event;
            if (matches) {
                setOrientation(media);
            }
        }

        const mediaQueryPortrait = window.matchMedia('(orientation: portrait)');
        const mediaQueryLandscape = window.matchMedia('(orientation: landscape)');

        if (mediaQueryPortrait.matches) {
            setOrientation('portrait');
        } else if (mediaQueryLandscape.matches) {
            setOrientation('landscape');
        }

        mediaQueryPortrait.addEventListener('change', handleOrientationChange);
        mediaQueryLandscape.addEventListener('change', handleOrientationChange);

        return () => {
            mediaQueryPortrait.removeEventListener('change', handleOrientationChange);
            mediaQueryLandscape.removeEventListener('change', handleOrientationChange);
        };
    }, []);

    return orientation;
};