export const MAX_MESSAGE_LENGTH = 700;
export const MAX_PHONE_LENGTH = 12;
export const INITIAL_DATA_ERROR = { company: '', email: '', comment: '' };
export const INITIAL_DATA_TEXT = { company: null, email: null, fullName: null, phone: null, comment: null };
export const INITIAL_DATA_FOCUS = { company: false, email: false, fullName: false, phone: false, comment: false };

export const FEEDBACK_TEXT_DATA = {
    title: 'Получить консультацию',
    buttonText: 'Отправить',
    iAgree: 'Я согласен на\u00A0',
    textIAgree: 'обработку персональных\u00A0данных',
    linkIAgree: '/terms',
    nameCompany: 'Название компании',
    fullName: 'Ваше имя',
    email: 'Email',
    phone: 'Номер телефона',
    yourQuestion: 'Ваш вопрос',
    limitCharacter: 'Не более 700 символов',
};

export const FEEDBACK_ERRORS_DATA = {
    emptyField: 'Поле не заполнено',
    warningText: 'Обязательно для заполнения',
    validateEmail: 'Некорректный email',
    validatePhone: 'Некорректный номер телефона',
    errorSendForm: 'Ошибка. Попробуйте отправить заявку ещё раз',
};

export const FEEDBACK_APP_SENT_DATA = {
    title: 'Спасибо!',
    description: 'Ваша заявка отправлена',
};

export enum FieldFormEnum {
    company = 'company',
    email = 'email',
    fullName = 'fullName',
    phone = 'phone',
    comment = 'comment',
}
