import React, { FC } from 'react';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import { FAQ_TEXTS } from '~/src/components/blocks/main/faq/faq.constants';
import { Accordion } from '~/src/components/common-components/faq-accordion/faq-accordion';
import * as Styled from './faq.styles';

interface FaqProps {
    offerUrl: string;
}

export const Faq: FC<FaqProps> = ({ offerUrl }) => {
    return (
        <Styled.Faq>
            <ContentContainer>
                <Styled.FaqHeader>
                    Вопросы и&nbsp;ответы
                </Styled.FaqHeader>
                <Styled.FaqBody>
                    {FAQ_TEXTS.map(({ question, answer, id }) => (
                        <Accordion question={question} answer={answer} id={id} key={id} offerUrl={offerUrl}/>
                    ))}
                </Styled.FaqBody>
            </ContentContainer>
        </Styled.Faq>
    );
};
