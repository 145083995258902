import { GtmService } from '~/src/services/web-analytics.service';
import { getAnalyticsEventData } from '~/src/helpers/web-analytics-events-data.helper';
import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventContentEnum,
    WebAnalyticsEventEnum,
} from '~/src/models/enums/web-analytics.enum';

export const sendDownloadLinkAnalytics = () => {
    GtmService.send(
        getAnalyticsEventData({
            eventAction: WebAnalyticsEventActionEnum.linkClick,
            eventLabel: 'usloviya_programmy',
            eventContent: WebAnalyticsEventContentEnum.howItWorks,
            event: WebAnalyticsEventEnum.vntEvent,
            eventCategory: WebAnalyticsEventCategoryEnum.mainPage,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};

export const sendCustomButtonClickAnalytics = () => {
    GtmService.send(
        getAnalyticsEventData({
            eventAction: WebAnalyticsEventActionEnum.buttonClick,
            eventLabel: 'poluchit_konsultaciu',
            eventContent: WebAnalyticsEventContentEnum.howItWorks,
            event: WebAnalyticsEventEnum.vntEvent,
            eventCategory: WebAnalyticsEventCategoryEnum.mainPage,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
        }),
    );
};