import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { MAX_767 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';

export const CarouselSlide = styled.div`
    display: flex;
    gap: ${rem(28)};
    padding: ${rem(24)};
    height: ${rem(204)};
    border-radius: ${BORDER_RADIUS.biggest20};
    background-color: ${COLORS.backgroundInverted};
    flex-wrap: nowrap;
    cursor: pointer;
    width: ${rem(416)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: ${rem(306)};
            padding: ${rem(16)};
            gap: ${rem(4)};
            height: ${rem(168)};
        `,
    )};
`;

export const ProductId = styled.span`
    padding-top: ${rem(4)};
    max-width: ${rem(30)};
    white-space: nowrap;
    color: ${COLORS.textPrimaryDark};
    ${FONTS.compact.regular.size8};
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: ${rem(330)};
    gap: ${rem(40)};
    flex-wrap: nowrap;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            gap: ${rem(16)};
            width: ${rem(260)};
        `,
    )};
`;

export const Title = styled.span`
    ${FONTS.wide.medium.size26};
    color: ${COLORS.textInverted};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size20};
        `,
    )};
`;

export const Description = styled.span<{ isLongTitle: boolean }>`
    width: 100%;
    ${FONTS.compact.regular.size14};
    color: ${COLORS.textPrimaryDark};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(p) => (p.isLongTitle ? 3 : 4)};
    -webkit-box-orient: vertical;
`;
