import React, { FC } from 'react';
import { ProductsCarousel } from '~/src/components/common-components/products-carousel/products-carousel';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import {
    authProductListTitle,
    productListTitle,
} from '~/src/components/blocks/main/products-list/products-list.constants';
import type { ProductsListItemInterface } from '~/src/repositories/products/products.repository';
import * as Styled from './products-list.styles';

interface ProductsListProps {
    productsData: ProductsListItemInterface[] | [];
    isOrganizationConnect: boolean;
}

export const ProductsList: FC<ProductsListProps> = ({ productsData, isOrganizationConnect }) => {
    const StyledProductList = isOrganizationConnect ? Styled.OrganizationConnectProductsList : Styled.ProductsList;

    return (
        <StyledProductList>
            <ContentContainer>
                <Styled.Title>{isOrganizationConnect ? authProductListTitle : productListTitle}</Styled.Title>
            </ContentContainer>
            <ProductsCarousel slidesData={productsData} />
        </StyledProductList>
    );
};
