import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { MAX_1023, MAX_1919, MAX_567 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';

export const Title = styled.label`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${rem(24)};
    cursor: pointer;
    transition: background 0.2s ease-out;
    position: relative;
    padding: ${rem(24)} 0;
`;

export const FaqAccordion = styled.div`
    width: 100%;
    line-height: ${rem(24)};

    &:not(:first-child) {
        ${Title} {
            border-top: ${rem(1)} solid ${COLORS.constantGreyscale200};
        }
    }
`;

export const Heading = styled.h3`
    ${FONTS.compact.medium.size24};
    max-width: ${rem(1100)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.compact.medium.size20};
            max-width: ${rem(812)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            ${FONTS.compact.medium.size17};
        `,
    )};
`;

export const Triangle = styled.div`
    display: flex;
    justify-content: center;
    text-align: right;
    transition: 200ms;

    svg {
        margin: auto;
        transform: rotateX(180deg);
    }
`;

export const Text = styled.div`
    display: block;
    margin-bottom: ${rem(24)};
    padding-right: ${rem(67)};
    opacity: 1;
    height: auto;
    ${FONTS.compact.regular.size17};
    transition-property: height, opacity;
    transition: 0.3s, 0.7s;
    max-width: ${rem(1100)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            max-width: ${rem(812)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding-right: ${rem(57)};
        `,
    )};

    a {
        color: ${COLORS.textPrimaryLinkDark};
        text-decoration: none;
        cursor: pointer;
    }
`;

export const Input = styled.input`
    width: 0;
    height: 0;
    appearance: none;
    position: absolute;

    &:checked ~ ${Title} ${Triangle} {
        transform: rotateX(180deg);
        transition: 200ms;
    }

    &:checked ~ ${Text} {
        margin: 0;
        opacity: 0;
        height: 0;
        transition-property: height, opacity;
        transition: 0.7s, 0.1s;
    }
`;
