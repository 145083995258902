import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { COLORS } from '~/src/styles/tokens/colors';
import {
    imgBlockBorderRadius,
} from '~/src/components/skeletons/caption-block-skeleton/caption-block-loaders/image-loader/image-loader.constants';
import { animationSpeed } from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton.constants';


export const ImageLoader = (props: IContentLoaderProps) => (
    <ContentLoader
        speed={animationSpeed}
        width={'500'}
        height={'350'}
        viewBox="0 0 500 350"
        backgroundColor={COLORS.constantGreyscale600}
        foregroundColor={COLORS.constantSkeletonForeground}
        {...props}
    >
        <rect x="0" y="0" rx={imgBlockBorderRadius} ry={imgBlockBorderRadius} width="500" height="350" />
    </ContentLoader>
);
