import * as React from 'react';
import { SVGProps } from 'react';

export const SvgLinkToIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.002 1.162c0-.583-.497-1.047-1.075-.979-.614.073-1.177.16-1.677.251A4.71 4.71 0 0 0 .434 4.25 26.742 26.742 0 0 0 0 9c0 1.853.206 3.497.434 4.75a4.71 4.71 0 0 0 3.816 3.816C5.503 17.794 7.147 18 9 18c1.853 0 3.497-.206 4.75-.434a4.71 4.71 0 0 0 3.816-3.816c.09-.5.178-1.062.25-1.675.069-.578-.395-1.075-.978-1.075-.52 0-.954.399-1.017.915a25.08 25.08 0 0 1-.223 1.477 2.71 2.71 0 0 1-2.206 2.206A24.747 24.747 0 0 1 9 16c-1.708 0-3.23-.19-4.392-.402a2.71 2.71 0 0 1-2.206-2.206A24.743 24.743 0 0 1 2 9c0-1.708.19-3.23.402-4.392a2.71 2.71 0 0 1 2.206-2.206c.444-.08.94-.159 1.48-.224.515-.062.914-.496.914-1.016Z"
            fill={props.fill || '#45B6FC'}

        />
        <path
            d="M18 8a.972.972 0 0 1-.983 1c-.553 0-1-.448-1.024-1a34.68 34.68 0 0 0-.441-4.12l-6.84 6.836A1 1 0 1 1 7.296 9.3l6.854-6.85a34.691 34.691 0 0 0-4.147-.446A1.035 1.035 0 0 1 9 .982a.972.972 0 0 1 1.002-.981c1.725.066 3.386.29 4.66.506 1.098.187 1.647.28 2.099.731.451.45.545 1 .732 2.097.217 1.272.44 2.941.506 4.664Z"
            fill={props.fill || '#45B6FC'}
        />
    </svg>
);

