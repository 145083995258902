import React, { FC, SVGProps } from 'react';

export const SvgSearchIcon: FC = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 16 16" {...props}>
        <path
            fill="#BBC1C7"
            d="M6.286 0a6.286 6.286 0 0 1 5.01 10.081l4.453 4.457a.857.857 0 1 1-1.213 1.21l-4.452-4.455A6.286 6.286 0 1 1 6.286 0Zm0 1.714a4.571 4.571 0 1 0 4.572 4.57 4.571 4.571 0 0 0-4.572-4.57Z"
        />
    </svg>
);
