import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_1279, MAX_767 } from '~/src/styles/tokens/break-points';

export const TitleRect = styled.rect`
    width: ${rem(500)};
    height: ${rem(32)};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            width: ${rem(450)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: ${rem(400)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
        `,
    )};
`;

export const TextBlockRect = styled.rect`
    width: ${rem(500)};
    height: ${rem(72)};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            width: ${rem(450)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: ${rem(400)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
        `,
    )};
`;

export const ButtonRect = styled.rect<{ xPos: number; yPos: number; isRightBtn?: boolean; }>`
    width: ${rem(150)};
    height: ${rem(52)};
    transform: translate(${p => rem(p.xPos)}, ${p => rem(p.yPos)});

    ${p => mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            ${p.isRightBtn && css`
                transform: translate(0rem, ${rem(220)});
            `};
        `,
    )};
`;
