import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useClientSide } from '~/src/hooks/use-client-side.hook';
import {
    selectIsConnectedCurrentOrganizationForButton,
} from '~/src/redux/user/user.selectors';
import { REWARD_TEXT_DATA } from '~/src/components/blocks/main/reward-banner/reward-banner.constants';
import {
    sendCustomButtonClickAnalytics,
} from '~/src/components/blocks/main/reward-banner/reward-banner.analytics';
import { SER_CON_AGENTS_URL } from '~/src/params';
import { SvgArrowReward } from '~/src/components/svg-components/arrow-reward-banner';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import * as Styled from './reward-banner.styles';

export const RewardBanner: FC = () => {
    const isConnectedCurrentOrganizationForButton = useSelector(selectIsConnectedCurrentOrganizationForButton);
    const onClientSide = useClientSide();
    const router = useRouter();

    const ArrowsTemplate = (
        <Styled.Arrow>
            <SvgArrowReward />
            <SvgArrowReward />
        </Styled.Arrow>
    );
    
    const getBlockContent = (index: number) => (
        <>
            <Styled.PartNumber>{`${REWARD_TEXT_DATA.blocks[index].id}/3`}</Styled.PartNumber>
            <Styled.Text>{REWARD_TEXT_DATA.blocks[index].text}</Styled.Text>
        </>
    );

    const onCustomButtonClick = async (buttonText: string) => {
        sendCustomButtonClickAnalytics(buttonText);
        await router.push({ pathname: `${SER_CON_AGENTS_URL}`, query: router.query });
    };

    const getNeedShowButton = () => {
        return !isConnectedCurrentOrganizationForButton && typeof isConnectedCurrentOrganizationForButton === 'boolean';
    };

    const getButtonComponent = () => {
        return <Styled.CustomButton
            onClick={() => onCustomButtonClick(REWARD_TEXT_DATA.buttonText)}
            textForSSR={REWARD_TEXT_DATA.buttonText}
            type={'button'}
        >
            {REWARD_TEXT_DATA.buttonText}
        </Styled.CustomButton>;
    };

    return (
        <Styled.RewardBanner>
            <ContentContainer>
                <Styled.Title>{REWARD_TEXT_DATA.title}</Styled.Title>
                <Styled.Steps>
                    {REWARD_TEXT_DATA.blocks.map((blockData, index) => {
                        return index === REWARD_TEXT_DATA.blocks.length - 1 ? (
                            <Styled.BigBlock key={blockData.id}>
                                {getBlockContent(index)}
                                {getNeedShowButton() && onClientSide &&
                                    getButtonComponent()
                                }
                                {!onClientSide && getButtonComponent()}
                            </Styled.BigBlock>
                        ) : (
                            <Styled.Block key={blockData.id}>
                                {getBlockContent(index)}
                            </Styled.Block>
                        );
                    })}
                    {ArrowsTemplate}
                    {ArrowsTemplate}
                </Styled.Steps>
            </ContentContainer>
        </Styled.RewardBanner>
    );
};
