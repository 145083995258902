import styled, { css } from 'styled-components';
import Image from 'next/image';
import { rem } from '~/src/helpers/rem-converter.helper';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_1279, MAX_1679, MAX_1919, MAX_767, MIN_768 } from '~/src/styles/tokens/break-points';

export const ImgContainer = styled.div`
    position: absolute;
    bottom: -20%;
    right: 0;
    width: ${rem(620)};
    z-index: ${Z_INDEX.under};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            right: ${rem(-40)};
            bottom: -25%;
            width: 40%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            right: ${rem(-60)};
            bottom: -15%;
            width: 46%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            width: ${rem(602)};
            height: ${rem(430)};
            bottom: -5%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            bottom: ${rem(74)};
            right: 0;
            width: ${rem(302)};
            height: ${rem(262)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            position: relative;
            bottom: 0;
            height: ${rem(246)};
            margin: ${rem(16)} auto ${rem(28)} auto;
        `,
    )};
`;

export const ImageLogo = styled(Image)`
    height: 100%;
    width: 100%;
    object-fit: contain;
`;

export const ImgLeadListContainer = styled.div`
    position: absolute;
    bottom: ${rem(-204)};
    right: 0;
    width: ${rem(791)};
    z-index: ${Z_INDEX.under};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            bottom: ${rem(-107)};
            width: ${rem(649)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            bottom: ${rem(-20)};
            width: ${rem(521)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            bottom: ${rem(-52)};
            right: ${rem(-135)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            right: ${rem(-170)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            display: none;
        `,
    )};
`;

export const ImageLeadList = styled(Image)`
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: ${rem(20)} ${rem(20)} 0 0;
`;

export const AuthImgContainer = styled(ImgContainer)`
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1919 },
        css`
            right: 0;
            bottom: unset;
            width: ${rem(400)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            display: none;
        `,
    )};
`;
