import React, { FC } from 'react';
import Image from 'next/image';
import { INCOME_TEXT_DATA } from '~/src/components/blocks/main/income-banner/income-banner-constants';
import BgTop from '~/src/images/background/backgroung-top.png';
import CoinsImg from '~/src/images/income-banner/coins.png';
import * as Styled from './income-banner.styles';

export const IncomeBanner: FC = () => {

    return (
        <Styled.IncomeBanner>
            <Styled.BackgroundTopContainer>
                <Image
                    width={4000}
                    height={375}
                    src={BgTop}
                    alt={'Фон'}
                />
            </Styled.BackgroundTopContainer>
            <Styled.Content>
                <Styled.FromBlock>
                    <Styled.From>{INCOME_TEXT_DATA.from}</Styled.From>
                    <Styled.Percent>{INCOME_TEXT_DATA.percent}</Styled.Percent>
                </Styled.FromBlock>
                <Styled.Description>
                    <Styled.GuaranteedIncome>{INCOME_TEXT_DATA.guaranteedIncome}</Styled.GuaranteedIncome>
                    <Styled.Condition>{INCOME_TEXT_DATA.condition}</Styled.Condition>
                </Styled.Description>
            </Styled.Content>
            <Styled.CoinsContainer>
                <Styled.ImageCoins
                    width={438}
                    height={374}
                    src={CoinsImg}
                    alt={'Монеты'}
                />
            </Styled.CoinsContainer>
        </Styled.IncomeBanner>
    );
};
