import styled, { css } from 'styled-components';
import Image, { ImageProps } from 'next/image';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import {
    MAX_1023,
    MAX_1279,
    MAX_1439,
    MAX_1679,
    MAX_1919,
    MAX_567,
    MAX_767,
    MIN_768,
} from '~/src/styles/tokens/break-points';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { COLORS } from '~/src/styles/tokens/colors';
import type { ComponentType } from 'react';
import { ContentContainer } from '~/src/styles/shared-css/content-container';

export const IncomeBanner = styled.section`
    position: relative;
    width: 100%;
    padding-top: ${rem(82)};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding-top: 0;
        `,
    )};
`;

export const BackgroundTopContainer = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    padding-bottom: 35%;
    z-index: ${Z_INDEX.under};
`;

export const Content = styled(ContentContainer)`
    display: flex;
    flex-wrap: nowrap;
    gap: ${rem(42)};
    align-items: center;
    padding: ${rem(40)} ${rem(180)};
    background: ${COLORS.gradientRedPurpleViolet};
    border-radius: ${BORDER_RADIUS.biggest20};
    color: ${COLORS.textPrimaryDark};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            padding: ${rem(52)} ${rem(140)} ${rem(52)} ${rem(60)};
        `,
    )};

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1279 },
        css`
            padding: ${rem(24)};
            ${FONTS.wide.medium.size84};
            gap: ${rem(16)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
            margin: 0 !important;
            padding: ${rem(20)};
            border-radius: unset;
        `,
    )};
`;

export const FromBlock = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
`;

export const From = styled.span`
    color: ${COLORS.textInverted};
    ${FONTS.wide.medium.size44};
    text-transform: uppercase;

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1279 },
        css`
            ${FONTS.wide.medium.size32};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};
`;

export const Percent = styled.span`
    ${FONTS.wide.medium.size180};
    margin-left: -${rem(16)};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            ${FONTS.wide.medium.size84};
            margin-left: ${rem(8)};
        `,
    )};
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(8)};
    max-width: ${rem(644)};
`;

export const GuaranteedIncome = styled.span`
    color: ${COLORS.textInverted};
    ${FONTS.wide.medium.size44};
    text-transform: uppercase;

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size28};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};
`;

export const Condition = styled.span`
    ${FONTS.compact.regular.size20};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.compact.regular.size14};
        `,
    )};
`;

export const CoinsContainer = styled.div`
    position: absolute;
    top: 58%;
    left: 74%;
    width: ${rem(438)};
    height: ${rem(374)};
    padding-top: ${rem(336)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            width: 50%;
            height: 50%;
            left: 60%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            width: 40%;
            height: 40%;
            left: 66%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1439 },
        css`
            width: 26%;
            height: 34%;
            left: 73%;
            top: 63%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            width: 26%;
            height: 34%;
            left: 73%;
            top: 58%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            top: 332%;
            left: calc(100% - ${rem(235)});
            width: ${rem(224)};
            height: ${rem(191)};
            padding-top: ${rem(191)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            left: calc(100% - ${rem(158)});
            top: 340%;
            width: ${rem(198)};
            height: ${rem(167)};
            padding-top: ${rem(167)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            top: 309%;
        `,
    )};
`;

export const ImageCoins = styled<ComponentType<ImageProps>>(Image)`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    object-fit: contain;
`;
