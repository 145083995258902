import React, { SVGProps } from 'react';

export const SvgCrossChips = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x={2} y={2} width={20} height={20} rx={10} fill="#F95721" />
        <path
            d="M14.72 15.78a.75.75 0 0 0 1.06-1.06L13.06 12l2.72-2.72a.75.75 0 1 0-1.06-1.06L12 10.94 9.28 8.22a.75.75 0 0 0-1.06 1.06L10.94 12l-2.72 2.72a.75.75 0 1 0 1.06 1.06L12 13.06l2.72 2.72Z"
            fill="#fff"
        />
    </svg>
);
