import styled, { css } from 'styled-components';
import Image from 'next/image';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import {
    MAX_1023,
    MAX_1919,
    MAX_567,
    MAX_767,
    MIN_1280,
    MIN_1920,
    MIN_568,
    MIN_768,
} from '~/src/styles/tokens/break-points';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

export const Container = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${rem(72)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding-bottom: 0;
        `,
    )};

    ${mediaQuery(
        { minWidth: MIN_568, maxWidth: MAX_767 },
        css`
            margin-top: ${rem(60)};
        `,
    )};
`;

export const BackgroundMiddle = styled.div`
    position: absolute;
    width: 100%;
    z-index: ${Z_INDEX.under};

    ${mediaQuery(
        { minWidth: MIN_768 },
        css`
            top: -17%;
            height: 350%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            top: -10%;
            height: 370%;
        `,
    )};
`;

export const Wrapper = styled.div`
    padding-bottom: ${rem(32)};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            padding-bottom: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { minWidth: MIN_1280 },
        css`
            margin-right: ${rem(80)};
        `,
    )};
`;

export const PersonalCabinet = styled.h2`
    ${FONTS.wide.medium.size32};
    margin-bottom: ${rem(20)};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.wide.medium.size44};
            margin-bottom: ${rem(24)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};

    ${mediaQuery(
        { minWidth: MIN_568, maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            ${FONTS.wide.medium.size20};
        `,
    )};
`;

export const TextData = styled.span`
    width: 100%;
    ${FONTS.compact.regular.size24};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.compact.regular.size20};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.compact.regular.size17};
        `,
    )};
`;

export const ImgBigContainer = styled.div`
    position: relative;
    width: 100%;
    margin: 0 auto;
`;

export const ImgSmallContainer = styled.div`
    position: relative;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin: 0 auto;
            width: 100%;
        `,
    )};
`;

export const ImageCabinet = styled(Image)`
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: ${BORDER_RADIUS.biggest20};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            border-radius: ${BORDER_RADIUS.big16};
        `,
    )};
`;

export const ImageBackground = styled(Image)`
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: top;
`;
