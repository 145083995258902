import { MouseEvent, TouchEvent } from 'react';
import { GetCorrectSlideIndexesArgsType } from '~/src/components/common-components/carousel/carousel.types';

export const isTouchEvent = (e: TouchEvent | MouseEvent): e is TouchEvent => {
    return e && 'touches' in e;
};

export const getEvent = (event: TouchEvent | MouseEvent) => {
    if (isTouchEvent(event)) {
        return event.touches[0];
    }

    return event;
};

export const getCorrectSlideIndexes = (args: GetCorrectSlideIndexesArgsType) => {
    const { nextIndex, slideIndex, infinite, initialSlideIndex, carouselChildrenLength } = args;
    let currentSlideIndex = infinite ? slideIndex - initialSlideIndex : slideIndex;
    let nextSlideIndex = infinite ? nextIndex - initialSlideIndex : nextIndex;

    //TODO из-за того, что при использовании стрелок для переключения слайда слайд сначала переключается,
    // и если нужно перемотать на начало или конец, то перематывается,
    // поэтому могут в beforeChange уйти некорректные индексы и условия ниже это фиксят
    if (nextSlideIndex === -1) {
        nextSlideIndex = carouselChildrenLength - 1;
    }
    if (nextSlideIndex === carouselChildrenLength) {
        nextSlideIndex = 0;
    }

    if (currentSlideIndex === -1) {
        currentSlideIndex = carouselChildrenLength - 1;
    }
    if (currentSlideIndex === carouselChildrenLength) {
        currentSlideIndex = 0;
    }

    if (currentSlideIndex === nextSlideIndex) {
        return null;
    }

    return { currentSlideIndex, nextSlideIndex };
};
