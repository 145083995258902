import { carouselAlign } from '~/src/components/common-components/carousel/carousel.constants';

export type AdditionalContentAlignType = 'start' | 'end' | 'center';
export type CarouselAlignType = keyof typeof carouselAlign;
export enum DirectionEnum {
    RIGHT = 'right',
    LEFT = 'left'
}

export type GetCorrectSlideIndexesArgsType = {
    nextIndex: number,
    slideIndex: number,
    infinite: boolean,
    initialSlideIndex: number,
    carouselChildrenLength: number
};
