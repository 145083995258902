import React, { FC } from 'react';
import { COLORS } from '~/src/styles/tokens/colors';

interface SvgSpinnerProps {
    className?: string;
    color?: COLORS;
    [key: string]: unknown;
}

export const SvgSpinner: FC<SvgSpinnerProps> = (props) => {
    const { color, className } = props;
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            data-testid={'spinner'}
        >
            <mask
                id="b"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={16}
                y={4}
                width={6}
                height={8}
            >
                <path d="m16.268 6.45 1.83-2.377A9.992 9.992 0 0 1 22 12h-3a6.992 6.992 0 0 0-2.732-5.55Z" fill="url(#a)"/>
            </mask>
            <g mask="url(#b)">
                <path fill="#fff" d="M1 1h22v22H1z"/>
            </g>
            <mask
                id="d"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={12}
                y={12}
                width={10}
                height={10}
            >
                <path
                    d="M12 19a7 7 0 0 0 7-7h3c0 .173-.005.348-.014.523-.28 5.34-4.7 9.481-9.986 9.477v-3Z"
                    fill="url(#c)"
                />
            </mask>
            <g mask="url(#d)">
                <path fill={color} d="M1 1h22v22H1z"/>
            </g>
            <mask
                id="f"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={2}
                y={12}
                width={10}
                height={10}
            >
                <path
                    d="M11.634 18.99c.123.006.245.01.366.01v3c-.173 0-.348-.005-.523-.014-5.34-.28-9.481-4.7-9.477-9.986h3a7 7 0 0 0 6.634 6.99Z"
                    fill="url(#e)"
                />
            </mask>
            <g mask="url(#f)">
                <path fill={color} d="M1 1h22v22H1z"/>
            </g>
            <mask
                id="h"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={2}
                y={1}
                width={12}
                height={11}
            >
                <path
                    d="M12.367 5.01A7 7 0 0 0 5 12H2c0-.174.005-.348.014-.524.289-5.515 4.994-9.752 10.51-9.463a1.5 1.5 0 1 1-.157 2.996Z"
                    fill="url(#g)"
                />
            </mask>
            <g mask="url(#h)">
                <path fill={color} d="M1 1h22v22H1z"/>
            </g>
            <defs>
                <linearGradient id="a" x1={19} y1={12} x2={16} y2={4} gradientUnits="userSpaceOnUse">
                    <stop stopOpacity={0.08}/>
                    <stop offset={1} stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="c" x1={12} y1={19} x2={19} y2={12} gradientUnits="userSpaceOnUse">
                    <stop stopOpacity={0.35}/>
                    <stop offset={1} stopOpacity={0.08}/>
                </linearGradient>
                <linearGradient id="e" x1={5} y1={12} x2={12} y2={19} gradientUnits="userSpaceOnUse">
                    <stop stopOpacity={0.65}/>
                    <stop offset={1} stopOpacity={0.35}/>
                </linearGradient>
                <linearGradient id="g" x1={14} y1={5} x2={5} y2={12} gradientUnits="userSpaceOnUse">
                    <stop/>
                    <stop offset={1} stopOpacity={0.65}/>
                </linearGradient>
            </defs>
            <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                from="0"
                to="360"
                dur="1s"
                repeatCount="indefinite"
            />
        </svg>
    );
};
