export const DEFAULT_FILE_SIZE_LIMIT_MB = 30;

export const ERROR_TEXT_COUNTS = 'Не более';

export const ERROR_TEXT_FORMAT = 'Неверный формат файла';

export const ERROR_FETCH = 'Ошибка загрузки файлов.\nПопробуйте ещё раз';

export const MAX_COUNT_TEXT = 'Загружено максимальное кол-во файлов';

export const MIN_COUNT_FILES = 1;

export const DEFAULT_FORMAT = 'JPEG, JPG, PDF, PNG';

// 1024 * 1024 = 1048576
export const MB_MULTIPLIER = 1_048_576;

export const DEFAULT_TYPES_FILES_ARRAY = ['image/jpg', 'image/jpeg', 'application/pdf', 'image/png'];

export const DOC_TYPES_ARRAY = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
];

export const IMG_TYPES_ARRAY = ['image/jpeg', 'image/png'];

export const PDF_TYPES_ARRAY = ['application/pdf'];