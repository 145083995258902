import { SER_CON_AGENTS_URL } from '~/src/params';

interface StepBlockInterface {
    head: string;
    subhead: string;
    steps: StepInterface[];
}

export interface StepInterface {
    id: number;
    description: string;
    linkText?: string;
    linkTo?: string;
    analyticsText?: string;
}

export const TEXTS: StepBlockInterface = {
    head: 'как это работает',
    subhead: `Агентами МТС могут стать Юрлица или\u00A0ИП, зарегистрированные на\u00A0территории\u00A0РФ.
     Регистрация и работа агента происходит в\u00A0три\u00A0простых\u00A0шага:`,
    steps: [
        {
            id: 1,
            description: 'в программе и\u00A0рекомендуете\nМТС своим клиентам',
            linkText: 'Вы\u00A0регистрируетесь\n',
            linkTo: SER_CON_AGENTS_URL,
            analyticsText: 'vy_registriruetes',
        },
        {
            id: 2,
            description: 'Передаёте нам контакты клиента через\u00A0портал — мы\u00A0подключаем клиента',
        },
        {
            id: 3,
            description: 'Получаете вознаграждение, пока\u00A0клиент пользуется сервисами\u00A0МТС',
        },
    ],
};
