import React from 'react';
import styled, { css } from 'styled-components';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_767 } from '~/src/styles/tokens/break-points';

export const StyledRect = styled.rect`
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 90%;
        `,
    )};
`;

const SvgSliderLoader = (props: IContentLoaderProps) => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 413 204"
        backgroundColor="#d7d0d0"
        foregroundColor="#f7f7f7"
        {...props}
    >
        <circle cx="552" cy="50" r="34" />
        <circle cx="573" cy="55" r="20" />
        <circle cx="592" cy="73" r="40" />
        <circle cx="706" cy="182" r="141" />
        <StyledRect x="0" y="0" rx="20" ry="20" width="100%" height="100%" />
    </ContentLoader>
);

export default SvgSliderLoader;