import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_1279, MAX_1439, MAX_1679, MAX_1919, MAX_767 } from '~/src/styles/tokens/break-points';

export const ContentContainer = styled.div`
    max-width: ${rem(1552)};
    margin: 0 auto;

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            margin: 0 ${rem(160)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            margin: 0 ${rem(88)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1439 },
        css`
            max-width: ${rem(1264)};
            margin: 0 ${rem(56)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            max-width: ${rem(1168)};
            margin: 0 ${rem(56)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            margin: 0 ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            max-width: 100%;
            margin: 0 ${rem(20)};
        `,
    )};
`;
