import { useQuery } from 'react-query';
import { captureException } from '@sentry/nextjs';
import { ConnectedSvcService } from '~/src/services/connected-service.service';
import type { UserState } from '~/src/redux/user/user.types';
import type { ConnectedApisInterface } from '~/src/repositories/connected-service/connected-service.repository';

export const useFetchCurrentAgentsApis = (workingPartyId: UserState['workingPartyId']):
    undefined | ConnectedApisInterface | Record<string, never> => {
    const queryKey = workingPartyId;
    const { data } = useQuery([queryKey], () => ConnectedSvcService.getCurrentConnectedApis(), {
        onError: (error) => {
            captureException(error);
            // eslint-disable-next-line no-console
            console.log(queryKey, error);
        },
        enabled: !!workingPartyId,
    });

    return data;
};