import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useHover } from '~/src/hooks/use-hover';
import { getTooltipPlacement } from '~/src/components/form-components/tooltip/tooltip.utils';
import { TooltipDirectionEnum } from '~/src/components/form-components/tooltip/tooltip.types';
import { SvgQuestionMark } from '~/src/components/svg-components/question-mark';
import type { TooltipInterface } from '~/src/components/form-components/tooltip/tooltip.types';
import * as Styled from './tooltip.styles';

/**
 * Used in Module Federation
 */

const Tooltip: FC<TooltipInterface> = ({
    hintText,
    width,
    direction,
    callback,
    className,
    isHover,
    onMouseOver,
    isControlled,
}) => {
    const svgQuestionMarkRef = useRef<SVGSVGElement | null>(null);
    const tooltipTextRef = useRef<HTMLDivElement | null>(null);
    const tooltipPlacement = useRef<TooltipDirectionEnum>(TooltipDirectionEnum.TOP);
    const [isVisible, setIsVisible] = useState(isHover);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const timeoutId = useRef<ReturnType<typeof setTimeout>>();

    const isInnerHover = useHover(tooltipRef);

    const handleMouseOver = () => {
        onMouseOver && onMouseOver();
        isHover && setIsVisible(true);
    };

    const onClickHandler = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.target instanceof HTMLAnchorElement) {
            callback && callback();
        }
    };

    useEffect(() => {
        setIsVisible(isHover);
    }, [isHover]);

    useEffect(() => {
        if (isVisible && !isInnerHover) {
            timeoutId.current = setTimeout(() => {
                setIsVisible(false);
            }, 2000);
        }

        return () => clearTimeout(timeoutId.current);
    }, [isVisible, isInnerHover]);

    useEffect(() => {
        if (!direction && svgQuestionMarkRef?.current && tooltipTextRef?.current) {
            const { left, right } = svgQuestionMarkRef.current.getBoundingClientRect();
            const tooltipWidth = tooltipTextRef.current.offsetWidth;

            const svgPlacementParams = {
                distanceToLeft: left,
                distanceToRight: document.documentElement.clientWidth - right,
            };

            tooltipPlacement.current = getTooltipPlacement(svgPlacementParams, tooltipWidth);
        }
    }, [direction]);

    return (
        <Styled.TooltipWrap className={className} isHover={isVisible} isControlled={isControlled} ref={tooltipRef}>
            <SvgQuestionMark iconRef={svgQuestionMarkRef} onMouseOver={handleMouseOver} />
            <Styled.TooltipText
                dangerouslySetInnerHTML={{ __html: hintText }}
                direction={direction || tooltipPlacement.current}
                width={width}
                onClick={onClickHandler}
                ref={tooltipTextRef}
            />
        </Styled.TooltipWrap>
    );
};

export default Tooltip;
