import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import {
    MAX_1279,
    MAX_1439,
    MAX_1679,
    MAX_1919,
    MAX_767,
} from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

export const HeroBlock = styled.section`
    position: relative;
    z-index: ${Z_INDEX.level2};
    width: 100%;
    overflow: hidden;
    background-color: ${COLORS.backgroundDarkPrimary};
    color: ${COLORS.textInverted};
`;

export const RegistrationArea = styled.div`
    padding: ${rem(20)} 0 ${rem(20)};
    display: inline-block;
    position: relative;
    line-height: 0;
    width: 100%;

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            padding: ${rem(20)} ${rem(160)} ${rem(20)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1679 },
        css`
            padding: ${rem(20)} ${rem(88)} ${rem(20)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1439 },
        css`
            padding: ${rem(20)} ${rem(56)} ${rem(20)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            padding: ${rem(20)} ${rem(40)} ${rem(20)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding: ${rem(20)} ${rem(20)} ${rem(20)};
        `,
    )};

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(
              to right,
              rgba(38, 54, 86, 0.8),
              rgba(38, 54, 86, 0.8),
              rgba(31, 43, 66, 0)
      );
    }
`;

export const RegistrationContent = styled.p`
    ${FONTS.compact.regular.size17};
    line-height: ${rem(24)};
    color: ${COLORS.textLightInverted};
    position: relative;
    z-index: ${Z_INDEX.level5};
    opacity: 1;
    margin: 0 auto;
    width: 100%;
    max-width: ${rem(1552)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            max-width: 100%;
        `,
    )};
`;

export const LogInLink = styled.span`
    color: ${COLORS.textPrimaryLinkDark};
    cursor: pointer;
`;
