import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_767 } from '~/src/styles/tokens/break-points';

export const Skeleton = styled.div<{width: string}>`
    width: ${p => p.width || '100%'};
    overflow: hidden;
    height: ${rem(204)};
    margin-left: ${rem(20)};

    ${mediaQuery({ maxWidth: MAX_767 },
        css`
            height: ${rem(168)};
        `,
    )};
`;