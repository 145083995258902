export interface TooltipInterface {
    hintText: string;
    width?: number;
    direction?: TooltipDirectionEnum;
    callback?: () => void;
    className?: string;
    onMouseOver?: () => void;
    isHover?: boolean;
    isControlled?: boolean;
}

export enum TooltipDirectionEnum {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM = 'bottom',
}
export type SvgQuestionMarkPlacementType = { distanceToRight: number; distanceToLeft: number };
