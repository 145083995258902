import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { selectIsAuthFinished } from '~/src/redux/user/user.selectors';
import { selectIsAuth } from '~/src/redux/id-token/id-token.selectors';
import { CaptionBlockContent } from '~/src/components/blocks/main/caption-block-content/caption-block-content';
import { LOG_IN, LOG_IN_NOTIFICATION } from '~/src/components/blocks/main/caption-block/caption-block.constants';
import { FULL_LOGIN_PATH } from '~/src/params';
import * as Styled from './caption-block.styles';

interface CaptionBlockProps {
    offerUrl: string;
}

export const CaptionBlock: FC<CaptionBlockProps> = ({ offerUrl }) => {
    const isAuthFinished = useSelector(selectIsAuthFinished);
    const isAuth = useSelector(selectIsAuth);
    const router = useRouter();

    useEffect(() => {
        const clientList = localStorage.getItem('leads');
        if (isAuth && isAuthFinished && clientList === 'redirect') {
            router.push({ pathname: '/leads', query: router.query });
            localStorage.setItem('leads', '');
        }
    }, [isAuthFinished]);

    const handleLogin = async () => {
        await router.push({ pathname: `${FULL_LOGIN_PATH}`, query: router.query });
        localStorage.setItem('leads', 'redirect');
    };

    return (
        <Styled.HeroBlock>
            <CaptionBlockContent offerUrl={offerUrl} isAuth={isAuth} isAuthFinished={isAuthFinished} />
            {isAuthFinished && !isAuth && (
                <Styled.RegistrationArea>
                    <Styled.RegistrationContent>
                        {LOG_IN_NOTIFICATION}
                        <Styled.LogInLink onClick={handleLogin}>{LOG_IN}</Styled.LogInLink>
                    </Styled.RegistrationContent>
                </Styled.RegistrationArea>
            )}
        </Styled.HeroBlock>
    );
};
