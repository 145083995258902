import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1279, MAX_767 } from '~/src/styles/tokens/break-points';
import { SvgInfoIcon } from '~/src/components/svg-components/info';
import { FONTS } from '~/src/styles/style-helpers/font-helper';

const lineClampStyles = css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const AuthContentWrapper = styled.div<{ isConnectedStatus: boolean; }>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: ${(p) => (p.isConnectedStatus ? rem(32) : rem(40))} 0;
    color: ${COLORS.textPrimaryDark};
    margin-top: ${rem(40)};

    &:after {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        background-image: linear-gradient(to right, rgba(38, 54, 86, 0.8), rgba(38, 54, 86, 0.8), rgba(31, 43, 66, 0));
        transform: translate3d(-50%, 0, 0);
        z-index: ${Z_INDEX.bottom};
    }

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(32)};
            padding: ${rem(32)} 0;
        `,
    )};
`;

export const AuthContentSubTitleWrapper = styled.div`
    align-items: center;
    flex-wrap: nowrap;
`;

export const InfoIcon = styled(SvgInfoIcon)`
    width: ${rem(32)};
    height: ${rem(32)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: ${rem(18)};
            height: ${rem(18)};
        `,
    )};
`;

export const AuthContentSubTitle = styled.h2`
    ${FONTS.wide.medium.size32};
    line-height: ${rem(36)};
    margin-left: ${rem(16)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size20};
        `,
    )};
`;

export const ButtonsArea = styled.div<{ isConnectedStatus?: boolean; alignCenterOnMobile: boolean }>`
    display: flex;
    align-items: center;
    margin-top: ${rem(32)};
    gap: ${rem(24)};

    ${(p) =>
        p.isConnectedStatus &&
        css`
            button {
                padding: ${rem(10)} ${rem(18)};
                min-width: auto;
            }
        `};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            margin-top: ${rem(32)};
        `,
    )};

    ${(p) =>
        mediaQuery(
            { maxWidth: MAX_767 },
            css`
                flex-direction: column;
                align-items: ${p.alignCenterOnMobile ? 'center' : 'start'};
                width: 100%;
            `,
        )};
`;

export const AuthContentTextContainer = styled.div<{ isConnectedStatus: boolean }>`
    display: block;
    max-width: ${rem(606)};
    margin-top: ${(p) => (p.isConnectedStatus ? 0 : rem(24))};

    ${(p) =>
        p.isConnectedStatus &&
        css`
            ${lineClampStyles};
        `};

    ${(p) =>
        mediaQuery(
            { maxWidth: MAX_767 },
            css`
                margin-top: ${p.isConnectedStatus ? 0 : rem(20)};
            `,
        )};
`;
