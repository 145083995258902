import { GetMappingDataType } from '~/src/components/common-components/feedback-form/feedback-form.types';

export const getMappingData: GetMappingDataType = (formData) => {
    const formDataFromEntries = Object.fromEntries(formData);
    const { company, email, fullName, phone, comment } = formDataFromEntries;

    return {
        companyName: String(company),
        email: String(email),
        fullName: String(fullName || ''),
        phoneNumber: String(phone || '').replace('+7', ''),
        question: String(comment),
        consentProcessingPersonalData: true,
    };
};
