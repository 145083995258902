import { COLORS } from '~/src/styles/tokens/colors';
import type { CommonButtonVisualInterface } from '~/src/components/form-components/main-button/main-button.types';

const primaryColorsSet = {
    textColor: COLORS.constantGreyscale0,
    bgColor: COLORS.controlsPrimaryActive,
    borderColor: COLORS.controlsPrimaryActive,
};

const secondaryColorsSet = {
    textColor: COLORS.textLightPrimary,
    bgColor: COLORS.controlsTertiaryActive,
    borderColor: COLORS.controlsTertiaryActive,
};

const alwaysWhiteColorsSet = {
    textColor: COLORS.textLightPrimary,
    bgColor: COLORS.constantGreyscale0,
    borderColor: COLORS.constantGreyscale0,
};

const disabledColorsSet = {
    textColor: COLORS.textTertiary,
    bgColor: COLORS.controlsInactive,
};

export const PRIMARY_BUTTON_PARAMS: CommonButtonVisualInterface = {
    normal: primaryColorsSet,
    pressed: primaryColorsSet,
    hover: primaryColorsSet,
    disabled: disabledColorsSet,
    spinnerColor: COLORS.constantGreyscale0,
};

export const SECONDARY_BUTTON_PARAMS: CommonButtonVisualInterface = {
    normal: secondaryColorsSet,
    pressed: secondaryColorsSet,
    hover: secondaryColorsSet,
    disabled: disabledColorsSet,
    spinnerColor: COLORS.accentNegative,
};

export const ALWAYS_WHITE_BUTTON_PARAMS: CommonButtonVisualInterface = {
    normal: alwaysWhiteColorsSet,
    pressed: alwaysWhiteColorsSet,
    hover: alwaysWhiteColorsSet,
    disabled: alwaysWhiteColorsSet,
    spinnerColor: COLORS.constantGreyscale900,
};
