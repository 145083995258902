import { useEffect, useState } from 'react';
import { isClientSide } from '~/src/helpers/ssr.helper';

export const useClientSide = () => {
    const [onClientSide, setOnClientSide] = useState(false);

    useEffect(() => {
        setOnClientSide(isClientSide());
    }, []);

    return onClientSide;
};