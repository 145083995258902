import React, { FC } from 'react';
import { rem } from '~/src/helpers/rem-converter.helper';
import SvgSliderLoader from '~/src/components/svg-components/loaders/slider-loader';
import * as Styled from './slider-skeleton.styles';

export const SliderSkeleton: FC<{ width: number }> = ({ width }) => {

    return (
        <Styled.Skeleton width={rem(width)}>
            <SvgSliderLoader/>
        </Styled.Skeleton>
    );
};
