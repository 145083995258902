import React, { FC, useEffect, useState, type MouseEvent } from 'react';
import { useMatchMedia } from '~/src/hooks/use-match-media.hook';
import { checkboxSizes } from '~/src/components/form-components/checkbox/checkbox.constants';
import { MediaWidthEnum } from '~/src/models/enums/media-width.enum';
import {
    type CheckboxProps,
    CheckboxSizeEnum,
    SvgTagsEnum,
} from '~/src/components/form-components/checkbox/checkbox.types';
import * as Styled from './checkbox.styles';

/**
 * Used in Module Federation
 */
const Checkbox: FC<CheckboxProps> = ({
    labelText = '',
    checked = false,
    onChange,
    disabled = false,
    className = '',
    name = '',
    LabelComponent = <></>,
    withTooltip,
    size = CheckboxSizeEnum.MEDIUM,
    indeterminate = false,
}) => {
    const [isDesktop] = useMatchMedia(MediaWidthEnum.desktop);
    const [isCheckedLocalState, setIsCheckedLocalState] = useState(checked);

    useEffect(() => {
        setIsCheckedLocalState(checked);
    }, [checked]);

    const handleChange = () => {
        onChange && onChange(!isCheckedLocalState);
        setIsCheckedLocalState((prev) => !prev);
    };

    const handleClickLabelText = (event: MouseEvent<HTMLSpanElement | SVGElement>) => {
        const target = event.target as HTMLElement;
        const nodeName = target?.tagName?.toLowerCase();
        const isSvgElement = nodeName === SvgTagsEnum.SVG || nodeName === SvgTagsEnum.PATH;

        if (withTooltip && isSvgElement) {
            event.preventDefault();
        }
    };

    return (
        <Styled.CheckBoxWrapper>
            <Styled.LabelWrapper className={className} data-testid={'checkbox'} disabled={disabled}>
                <Styled.CheckBox>
                    <Styled.HiddenInput
                        name={name}
                        type={'checkbox'}
                        checked={isCheckedLocalState}
                        onChange={handleChange}
                        disabled={disabled}
                        isDesktop={isDesktop}
                        data-testid={'hidden-input'}
                    />
                    <Styled.CustomCheckboxIcon
                        size={checkboxSizes[size]}
                        checked={isCheckedLocalState}
                        disabled={disabled}
                        indeterminate={indeterminate}
                    />
                </Styled.CheckBox>
                <Styled.LabelText data-testid={'label-text'} onClick={handleClickLabelText}>
                    {labelText || LabelComponent}
                </Styled.LabelText>
            </Styled.LabelWrapper>
        </Styled.CheckBoxWrapper>
    );
};

export default Checkbox;
