import React, { FC, RefObject } from 'react';

interface SvgQuestionMarkProps {
    iconRef: RefObject<SVGSVGElement>;
    onMouseOver: (event: React.MouseEvent<SVGSVGElement>) => void;
}

export const SvgQuestionMark: FC<SvgQuestionMarkProps> = ({ iconRef, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        viewBox="0 0 16 16"
        style={{ cursor: 'pointer' }}
        ref={iconRef}
        {...props}
    >
        <path fill="#969FA8" fillRule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" clipRule="evenodd" />
        <path
            fill="#fff"
            d="M7.283 9.383h1.615c0-1.01.273-1.333 1.061-1.82.784-.48 1.236-1.124 1.236-2.049 0-1.491-1.202-2.36-2.893-2.36-1.547 0-2.809.809-2.847 2.514h1.739c.025-.695.54-1.066 1.1-1.066.574 0 1.039.384 1.039.976 0 .537-.367.903-.93 1.249-.8.498-1.2 1.09-1.12 2.556Zm.84 2.728c.545 0 1.018-.456 1.022-1.023a1.035 1.035 0 0 0-1.022-1.014 1.018 1.018 0 1 0 0 2.037Z"
        />
    </svg>
);
