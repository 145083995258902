import React from 'react';

export const SvgChevron = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={8}
        fill="none"
        viewBox="0 0 16 8"
    >
        <path
            fill="#969FA8"
            d="M1.012 0c-.9 0-1.35 1.077-.714 1.706l3.13 3.096C5.582 6.934 6.66 8 8 8c1.34 0 2.417-1.066 4.573-3.198l3.129-3.096c.637-.63.186-1.706-.714-1.706-.268 0-.525.105-.715.293l-3.13 3.095c-1.118 1.106-1.815 1.79-2.389 2.223-.517.39-.698.39-.751.39h-.006c-.053 0-.234 0-.751-.39-.574-.432-1.272-1.117-2.39-2.223L1.727.293A1.016 1.016 0 0 0 1.012 0Z"
        />
    </svg>
);
