import styled, { css } from 'styled-components';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';
import { rem } from '~/src/helpers/rem-converter.helper';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_767, MAX_567, MIN_568, MIN_1920 } from '~/src/styles/tokens/break-points';
import MainButton from '~/src/components/form-components/main-button/main-button';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';

export const RewardBanner = styled.section`
    width: 100%;
    margin-top: ${rem(88)};
    z-index: ${Z_INDEX.level1};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            margin-top: ${rem(50)};
        `,
    )}
`;

export const Title = styled.h2`
    display: flex;
    margin-bottom: ${rem(32)};
    ${FONTS.wide.medium.size32};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.wide.medium.size44};
            margin-bottom: ${rem(52)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};

    ${mediaQuery(
        { minWidth: MIN_568, maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            ${FONTS.wide.medium.size20};
            margin-bottom: ${rem(24)};
        `,
    )};
`;

export const Steps = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${rem(24)} ${rem(36)};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            display: flex;
            flex-direction: column;
            gap: ${rem(20)};
            width: 100%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            gap: ${rem(16)};
        `,
    )};
`;

export const PartNumber = styled.span`
    ${FONTS.compact.regular.size14};
    line-height: 32px;
    margin-bottom: ${rem(4)};
`;

export const Text = styled.span`
    ${FONTS.compact.regular.size20};
    line-height: ${rem(28)};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            ${FONTS.compact.regular.size14};
        `,
    )};
`;

export const Arrow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 70%;

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            display: none;
        `,
    )};
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${rem(20)} ${rem(28)};
    border-radius: ${BORDER_RADIUS.biggest20};
    background-color: ${COLORS.backgroundLightSecondary};
    backdrop-filter: blur(2px);

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: 100%;
        `,
    )};
`;

export const BigBlock = styled(Block)`
    height: fit-content;
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    justify-content: space-between;
    background: rgba(250, 205, 205, 0.6);
    backdrop-filter: blur(5px);
`;

export const CustomButton = styled(MainButton)`
    ${FONTS.sans.medium.size17};
    width: unset;
    margin: ${rem(24)} auto 0 0;
    padding: ${rem(14)} ${rem(24)};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            width: 100%;
            max-width: 100%;
            margin-top: ${rem(16)};
        `,
    )}
`;
