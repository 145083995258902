export const littleTitle = 'Агентская программа B2B';
export const bigTitle = (
    <>
        Зарабатывайте,
        <br />
        рекомендуя продукты&nbsp;МТС
    </>
);
export const authBigTitle = 'МТС АГЕНТЫ';
export const subTitle = (
    <>Рекомендуйте продукты для бизнеса своим клиентам и получайте вознаграждение&nbsp;от их оборота с МТС</>
);
export const buttonText = 'Подключиться к программе';
