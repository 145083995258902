import React, { FC, ReactNode } from 'react';
import * as Styled from './big-title.styles';

interface BigTitleProps {
    marginTop?: number;
    bigTitleText: string | ReactNode;
}

export const BigTitle: FC<BigTitleProps> = ({ marginTop, bigTitleText }) => {
    return <Styled.BigTitle marginTop={marginTop}>{bigTitleText}</Styled.BigTitle>;
};
