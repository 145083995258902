import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import MainButton from '~/src/components/form-components/main-button/main-button';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { MAX_1279, MAX_767, MIN_768 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

const lineClampStyles = css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const AuthContentWrapper = styled.div<{ isConnectedStatus: boolean; withoutGradient?: boolean; }>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: ${(p) => (p.isConnectedStatus ? rem(32) : rem(40))} 0;
    color: ${COLORS.textPrimaryDark};
    margin-top: ${rem(40)};

    ${p => !p.withoutGradient && css`
        &:after {
            content: '';
            display: block;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            background-image: linear-gradient(to right, rgba(38, 54, 86, 0.8), rgba(38, 54, 86, 0.8), rgba(31, 43, 66, 0));
            transform: translate3d(-50%, 0, 0);
            z-index: ${Z_INDEX.bottom};
        }
    `}

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(32)};
            padding: ${rem(32)} 0;
        `,
    )};
`;

export const ContentContainerCaption = styled(ContentContainer)`
    position: relative;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            display: flex;
            flex-direction: column-reverse;
            margin: 0 ${rem(20)};
        `,
    )};
`;

export const OrganizationName = styled.div`
    ${lineClampStyles};
`;

export const Content = styled.div`
    position: relative;
    width: 53%;
    padding: ${rem(40)} 0 ${rem(60)};
    flex-direction: column;

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1279 },
        css`
            padding: ${rem(40)} 0;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            padding: 0 0 ${rem(32)};
        `,
    )};
`;

export const LittleTitle = styled.span`
    color: ${COLORS.textTertiary};
    ${FONTS.text.regular.size14};
    line-height: ${rem(20)};
`;

export const Subtitle = styled.span`
    margin-top: ${rem(20)};
    color: ${COLORS.textPrimaryDark};
    ${FONTS.compact.medium.size17};
    max-width: ${rem(401)};

    ${mediaQuery(
        { minWidth: MIN_768 },
        css`
            max-width: ${rem(446)};
        `,
    )};
`;

export const ButtonsArea = styled.div<{ isConnectedStatus?: boolean; alignCenterOnMobile: boolean; }>`
    display: flex;
    align-items: center;
    margin-top: ${rem(32)};
    gap: ${rem(24)};

    ${(p) =>
        p.isConnectedStatus &&
        css`
            button {
                padding: ${rem(10)} ${rem(18)};
                min-width: auto;
            }
        `};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            margin-top: ${rem(32)};
        `,
    )};

    ${(p) =>
        mediaQuery(
            { maxWidth: MAX_767 },
            css`
                flex-direction: column;
                align-items: ${p.alignCenterOnMobile ? 'center' : 'start'};
                width: 100%;
            `,
        )};
`;

export const CustomButton = styled(MainButton)`
    min-width: ${rem(172)};
    width: unset;
    ${FONTS.compact.medium.size17};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            max-width: 100%;
        `,
    )};
`;

export const LinkToSerCon = styled.a`
    ${FONTS.compact.medium.size17};
    color: ${COLORS.textPrimaryLinkDark};
    text-decoration: none;
`;
