import React, { FC } from 'react';
import { DescriptionBlockLoader } from '~/src/components/skeletons/caption-block-skeleton/caption-block-loaders/description-block-loader/description-block-loader';
import { TitleLoader } from '~/src/components/skeletons/caption-block-skeleton/caption-block-loaders/title-loader/title-loader';
import { ImageLoader } from '~/src/components/skeletons/caption-block-skeleton/caption-block-loaders/image-loader/image-loader';
import { SkeletonLoaderTypeEnum } from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton.types';
import { IContentLoaderProps } from 'react-content-loader';
import * as Styled from './caption-block-skeleton.styles';

interface CaptionBlockSkeletonProps extends IContentLoaderProps {
    type: SkeletonLoaderTypeEnum;
}

export const CaptionBlockSkeleton: FC<CaptionBlockSkeletonProps> = ({ type, ...restProps }) => {
    switch (type) {
        case SkeletonLoaderTypeEnum.TEXT:
            return (
                <Styled.Skeleton>
                    <DescriptionBlockLoader {...restProps} />
                </Styled.Skeleton>
            );
        case SkeletonLoaderTypeEnum.TITLE:
            return (
                <Styled.Skeleton isTitle marginTop={12}>
                    <TitleLoader {...restProps} />
                </Styled.Skeleton>
            );
        default:
            return (
                <Styled.Skeleton isImage>
                    <ImageLoader {...restProps} />
                </Styled.Skeleton>
            );
    }
};
