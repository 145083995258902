import React, { FC } from 'react';
import { carouselAlign } from '~/src/components/common-components/carousel/carousel.constants';
import type { CarouselAlignType } from '~/src/components/common-components/carousel/carousel.types';
import * as Styled from '~/src/components/common-components/carousel/carousel.styles';

interface CarouselDotsProps {
    infinite: boolean;
    slidesQuantity: number;
    currentSlideIndex: number;
    initialSlideIndex: number;
    containerLeftPadding: number;
    align: CarouselAlignType;
}
export const CarouselDots: FC<CarouselDotsProps> = ({
    infinite,
    slidesQuantity,
    currentSlideIndex,
    align,
    initialSlideIndex,
    containerLeftPadding,
}) => {
    const dotsQuantity = infinite ? slidesQuantity + initialSlideIndex : slidesQuantity;

    return (
        <Styled.CarouselDots marginLeft={align === carouselAlign.center ? -(containerLeftPadding / 2) : 0}>
            {Array.from({ length: dotsQuantity }, (_, idx) => {
                if (!infinite) {
                    return <Styled.CarouselDot key={idx} isActiveSlide={idx === currentSlideIndex} />;
                }

                if (infinite && idx >= initialSlideIndex) {
                    return <Styled.CarouselDot key={idx} isActiveSlide={idx === currentSlideIndex} />;
                }
            })}
        </Styled.CarouselDots>
    );
};
