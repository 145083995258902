import { capitalizeFirstLetter } from './personal-status-block.utils';
import * as Styled from './personal-status-block.styles';

const beginningText = 'для';

export const connectionStagesStatusData = {
    EVERY_CONNECTED: {
        getText: (name: string) => (
            <>
                {!!name?.length && (
                    <>
                        {name}, <br />
                    </>
                )}
                <Styled.SelectedText>
                    {capitalizeFirstLetter(beginningText, !name?.length)} работы с лидами
                </Styled.SelectedText>{' '}
                выберите нужный&nbsp;бизнес-аккаунт вверху справа.
                <br />
                <br />
                Если вы хотите подключить к&nbsp;программе МТС Агенты другой&nbsp;бизнес-аккаунт — нажмите
                «Подключиться к&nbsp;программе»
            </>
        ),
        title: '',
    },
    SOME_CONNECTED: {
        title: 'Подпишите оферту',
        getText: (name: string) => (
            <>
                {!!name?.length && (
                    <>
                        {name}, <br />
                    </>
                )}
                <Styled.SelectedText>
                    {capitalizeFirstLetter(beginningText, !name?.length)} работы с лидами
                </Styled.SelectedText>{' '}
                или <Styled.SelectedText>подписания оферты</Styled.SelectedText> выберите нужный бизнес&#8209;аккаунт
                вверху справа.
                <br />
                <br />
                Если вы хотите подключить к&nbsp;программе МТС Агенты другой бизнес&#8209;аккаунт — нажмите
                «Подключиться к&nbsp;программе»
            </>
        ),
    },
    NONE_CONNECTED: {
        title: 'Проверка бизнес-аккаунта',
        getText: (name: string) => (
            <>
                {!!name?.length && (
                    <>
                        {name}, <br />
                    </>
                )}
                <Styled.SelectedText>
                    {capitalizeFirstLetter(beginningText, !name?.length)} подписания оферты
                </Styled.SelectedText>{' '}
                выберите нужный бизнес&#8209;аккаунт вверху справа.
                <br />
                <br />
                Если вы хотите подключить ещё одну компанию — нажмите «Подключиться к&nbsp;программе»
            </>
        ),
    },
    DEFAULT: null,
};
