export enum MediaWidthEnum {
    desktopWideXL = '(min-width: 1679px)',
    desktopWideX = '(max-width: 1679px)',
    desktop = '(min-width: 1280px)',
    desktopMedium = '(max-width: 1439px)',
    desktopWide = '(max-width: 1365px)',
    tabletWide = '(max-width: 1279px)',
    tablet = '(min-width: 768px) and (max-width: 1279px)',
    tabletSmall = '(max-width: 1023px)',
    mobile = '(max-width: 767px)',
    mobileXS = '(max-width: 567px)',
}