import React, { FC, ReactNode } from 'react';
import { BigTitle } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/big-title/big-title';
import { bigTitle, subTitle } from '~/src/components/blocks/main/caption-block-content/caption-block-content.constants';
import * as Styled from '~/src/components/blocks/main/caption-block-content/caption-block-content.styles';

interface DefaultStatusContentProps {
    componentButtons: ReactNode;
}

export const DefaultStatusContent: FC<DefaultStatusContentProps> = ({ componentButtons }) => {
    return (
        <>
            <BigTitle marginTop={12} bigTitleText={bigTitle} />
            <Styled.Subtitle>{subTitle}</Styled.Subtitle>
            <Styled.ButtonsArea alignCenterOnMobile>{componentButtons}</Styled.ButtonsArea>
        </>
    );
};
