import React, { FC } from 'react';
import AgentsBrandLogo from '~/src/images/caption-block/agents-brand-logo.png';
import { IMAGE_QUALITY } from '~/src/models/constants/image-quality.constants';
import { CounterpartyConnectStatusEnum } from '~/src/models/enums/counterparty-connect-status.enum';
import { CaptionBlockSkeleton } from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton';
import LeadListImage from '~/src/images/personal-cabinet/lead-list.png';
import { SkeletonLoaderTypeEnum } from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton.types';
import * as Styled from './image-status-block.styles';

interface ImageStatusBlockProps {
    isAuth: boolean;
    userPartyId: string;
    workingPartyId: string;
    organizationConnectionStatus: CounterpartyConnectStatusEnum;
    contentIsLoading: boolean;
}

export const ImageStatusBlock: FC<ImageStatusBlockProps> = ({
    isAuth,
    userPartyId,
    workingPartyId,
    organizationConnectionStatus,
    contentIsLoading,
}) => {
    if (contentIsLoading) {
        return (
            <Styled.AuthImgContainer>
                <CaptionBlockSkeleton type={SkeletonLoaderTypeEnum.IMAGE} />
            </Styled.AuthImgContainer>
        );
    }

    if (!isAuth || workingPartyId === userPartyId) {
        return (
            <Styled.ImgContainer>
                <Styled.ImageLogo
                    width={710}
                    height={560}
                    src={AgentsBrandLogo}
                    alt={'Лого'}
                    priority={true}
                    quality={IMAGE_QUALITY.MEDIUM_HIGH}
                />
            </Styled.ImgContainer>
        );
    }

    if (organizationConnectionStatus === CounterpartyConnectStatusEnum.CONNECTED) {
        return (
            <Styled.ImgLeadListContainer>
                <Styled.ImageLeadList
                    width={791}
                    height={550}
                    src={LeadListImage}
                    alt={'Список лидов'}
                    priority={true}
                    quality={IMAGE_QUALITY.MEDIUM_HIGH}
                />
            </Styled.ImgLeadListContainer>
        );
    }

    return (
        <Styled.AuthImgContainer>
            <Styled.ImageLogo
                width={710}
                height={560}
                src={AgentsBrandLogo}
                alt={'Лого'}
                priority={true}
                quality={IMAGE_QUALITY.MEDIUM_HIGH}
            />
        </Styled.AuthImgContainer>
    );
};
