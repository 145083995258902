import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1279, MAX_767 } from '~/src/styles/tokens/break-points';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

export const AuthContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: ${rem(40)} 0;
    color: ${COLORS.textPrimaryDark};
    margin-top: ${rem(40)};

    &:after {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        background-image: linear-gradient(to right, rgba(38, 54, 86, 0.8), rgba(38, 54, 86, 0.8), rgba(31, 43, 66, 0));
        transform: translate3d(-50%, 0, 0);
        z-index: ${Z_INDEX.bottom};
    }

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(32)};
            padding: ${rem(32)} 0;
        `,
    )};
`;

export const ButtonsArea = styled.div<{ alignCenterOnMobile: boolean }>`
    display: flex;
    align-items: center;
    margin-top: ${rem(32)};
    gap: ${rem(24)};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            margin-top: ${rem(32)};
            gap: ${rem(32)};
        `,
    )};

    ${(p) =>
        mediaQuery(
            { maxWidth: MAX_767 },
            css`
                flex-direction: column;
                align-items: ${p.alignCenterOnMobile ? 'center' : 'start'};
                width: 100%;
            `,
        )};
`;

export const ContentText = styled.div`
    display: block;
    max-width: ${rem(606)};
`;

export const SelectedText = styled.span`
    ${FONTS.compact.bold.size17};
`;
