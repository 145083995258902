import { MainButtonVisualEnum } from '~/src/components/form-components/main-button/main-button-visual.enum';
import {
    ALWAYS_WHITE_BUTTON_PARAMS,
    PRIMARY_BUTTON_PARAMS,
    SECONDARY_BUTTON_PARAMS,
} from '~/src/components/form-components/main-button/main-button.constants';

export const getColorSet = (setType: keyof typeof MainButtonVisualEnum) => {
    switch (setType) {
        case MainButtonVisualEnum.primary:
            return PRIMARY_BUTTON_PARAMS;
        case MainButtonVisualEnum.secondary:
            return SECONDARY_BUTTON_PARAMS;
        case MainButtonVisualEnum.alwaysWhite:
            return ALWAYS_WHITE_BUTTON_PARAMS;
        default:
            return PRIMARY_BUTTON_PARAMS;
    }
};