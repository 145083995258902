import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { MAX_1023, MAX_1279, MAX_1919, MAX_567, MAX_767, MIN_1920, MIN_768 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import MainButton from '~/src/components/form-components/main-button/main-button';

export const HowItWorks = styled.section`
    width: 100%;
    padding-top: ${rem(120)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding-top: ${rem(80)};
        `,
    )};
`;

export const Head = styled.h2`
    width: 100%;
    ${FONTS.wide.medium.size44};
    text-transform: uppercase;

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.wide.medium.size58};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size28};
        `,
    )};
`;

export const Subhead = styled.span`
    max-width: ${rem(720)};
    margin-top: ${rem(20)};
    ${FONTS.compact.regular.size24};
    line-height: ${rem(28)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            max-width: ${rem(592)};
            margin-top: ${rem(24)};
            ${FONTS.compact.regular.size20};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            width: 100%;
        `,
    )};

    ${mediaQuery(
        { minWidth: MIN_768, maxWidth: MAX_1023 },
        css`
            width: 100%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            ${FONTS.sans.regular.size17};
            line-height: ${rem(24)};
        `,
    )};
`;

export const Instruction = styled.div`
    width: 100%;
    margin-top: ${rem(32)};
    padding: ${rem(40)};
    background-color: ${COLORS.backgroundLightSecondary};
    border-radius: ${BORDER_RADIUS.biggest20};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            padding: ${rem(52)} ${rem(65)};
            margin-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding: ${rem(24)} ${rem(20)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            padding: ${rem(20)} ${rem(16)};
        `,
    )};
`;

export const Steps = styled.div`
    display: flex;
    gap: ${rem(16)};
    justify-content: space-between;
    width: 100%;

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            flex-direction: column;
            gap: ${rem(32)};
        `,
    )};
`;

export const ButtonsArea = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${rem(52)};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            padding-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            flex-direction: column;
            align-items: flex-start;
            padding-top: ${rem(32)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            flex-direction: column;
            align-items: center;
            width: 100%;
        `,
    )};
`;

export const CustomButton = styled(MainButton)`
    width: unset;
    ${FONTS.sans.medium.size17};
    cursor: pointer;
    border: 2px solid ${COLORS.backgroundLightStroke};

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            height: ${rem(72)};
            ${FONTS.sans.medium.size24};
            padding: ${rem(24)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            width: 100%;
            max-width: 100%;
        `,
    )};
`;

export const DownloadLinkWrap = styled.div`
    display: flex;
    align-items: center;
    margin-left: ${rem(36)};
    cursor: pointer;

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-left: 0;
            margin-top: ${rem(28)};
        `,
    )};
`;

export const DownloadLink = styled.a`
    padding-left: ${rem(10)};
    ${FONTS.compact.medium.size20};
    color: ${COLORS.textPrimaryLink};
    text-decoration: none;
    outline: none;

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.sans.medium.size24};
        `,
    )};
`;
