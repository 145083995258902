import axios, { AxiosRequestConfig } from 'axios';
import { API_HOST_ENV } from '~/src/params';
import { getRequestUrlHelper } from '~/src/helpers/get-request-url.helper';
import {
    apiKeyGetCurrentConnected,
    defaultUrlGetCurrentConnected,
} from '~/src/repositories/connected-service/api-urls';

export interface ConnectedApisInterface {
    organizationPartyId: string;
    connected: boolean;
}

export const getCurrentConnectedApis = async (
    isSSR?: boolean,
): Promise<ConnectedApisInterface | Record<string, never>> => {
    const url = getRequestUrlHelper({
        isSSR,
        apiKey: apiKeyGetCurrentConnected,
        defaultUrl: defaultUrlGetCurrentConnected,
    });

    const { data } = await axios.get<ConnectedApisInterface>(url);
    return data;
};

export const getOffer = async (offerLink: string) => {
    const config: AxiosRequestConfig = {
        responseType: 'blob',
    };
    return await axios.get<Blob>(`${API_HOST_ENV}${offerLink}`, config);
};
