import React, { FC, SyntheticEvent } from 'react';
import { CounterpartyConnectStatusEnum } from '~/src/models/enums/counterparty-connect-status.enum';
import { SvgLinkToIcon } from '~/src/components/svg-components/link-to-icon';
import { COLORS } from '~/src/styles/tokens/colors';
import { PORTAL_URL } from '~/src/components/blocks/main/faq/faq.constants';
import { ConditionProgramButton } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/condition-program-button/condition-program-button';
import { MainButtonVisualEnum } from '~/src/components/form-components/main-button/main-button-visual.enum';
import {
    buttonCreateLeadData,
    buttonMoveToLeadListData,
    howToConnectLinkText,
    signOfferButtonText,
} from './buttons-status-block.constants';
import * as Styled from './buttons-status-block.styles';

interface ButtonsStatusBlockProps {
    onClickDownloadLink: (event: SyntheticEvent<HTMLElement>) => void;
    onClickButton: (data: { text: string; url: string } | null) => void;
    isLoading: boolean;
    isConnectedCurrentOrganizationForButton: boolean | null;
    organizationConnectionStatus: CounterpartyConnectStatusEnum;
    buttonData: { text: string; url: string } | null;
    hasLegal?: boolean;
}

export const ButtonsStatusBlock: FC<ButtonsStatusBlockProps> = ({
    organizationConnectionStatus,
    onClickButton,
    isLoading,
    onClickDownloadLink,
    isConnectedCurrentOrganizationForButton,
    buttonData,
    hasLegal,
}) => {
    if (!hasLegal) {
        return (
            <>
                <Styled.CustomButton
                    onClick={() => onClickButton(buttonData)}
                    isLoading={isLoading || !buttonData}
                    textForSSR={buttonData?.text}
                    type={'button'}
                    typeVisual={MainButtonVisualEnum.primary}
                >
                    {buttonData?.text}
                </Styled.CustomButton>
                <ConditionProgramButton onClick={onClickDownloadLink} />
            </>
        );
    }

    switch (organizationConnectionStatus) {
        case CounterpartyConnectStatusEnum.DATA_VALIDATION:
            return (
                <>
                    <Styled.DownloadLinkWrap>
                        <SvgLinkToIcon fill={COLORS.textPrimaryLinkDark} />
                        <Styled.DownloadLink target="_blank" href={PORTAL_URL} rel={'noopener noreferer'}>
                            {howToConnectLinkText}
                        </Styled.DownloadLink>
                    </Styled.DownloadLinkWrap>
                    <ConditionProgramButton onClick={onClickDownloadLink} />
                </>
            );
        case CounterpartyConnectStatusEnum.CAN_CONNECT:
            return (
                <>
                    <Styled.SignOfferButton
                        onClick={() => onClickButton(buttonData)}
                        isLoading={isLoading || !buttonData}
                        textForSSR={signOfferButtonText}
                        type={'button'}
                        typeVisual={
                            isConnectedCurrentOrganizationForButton
                                ? MainButtonVisualEnum.secondary
                                : MainButtonVisualEnum.primary
                        }
                    >
                        {signOfferButtonText}
                    </Styled.SignOfferButton>
                    <ConditionProgramButton onClick={onClickDownloadLink} />
                </>
            );
        case CounterpartyConnectStatusEnum.CONNECTED:
            return (
                <>
                    <Styled.CustomButton
                        onClick={() => onClickButton(buttonMoveToLeadListData)}
                        isLoading={isLoading || !buttonData}
                        textForSSR={buttonMoveToLeadListData.text}
                        type={'button'}
                        typeVisual={MainButtonVisualEnum.secondary}
                    >
                        {buttonMoveToLeadListData.text}
                    </Styled.CustomButton>
                    <Styled.CustomButton
                        onClick={() => onClickButton(buttonCreateLeadData)}
                        isLoading={isLoading || !buttonData}
                        textForSSR={buttonCreateLeadData.text}
                        type={'button'}
                        typeVisual={MainButtonVisualEnum.primary}
                    >
                        {buttonCreateLeadData.text}
                    </Styled.CustomButton>
                </>
            );
        default:
            return (
                <>
                    <Styled.CustomButton
                        onClick={() => onClickButton(buttonData)}
                        isLoading={isLoading || !buttonData}
                        textForSSR={buttonData?.text}
                        type={'button'}
                        typeVisual={MainButtonVisualEnum.primary}
                    >
                        {buttonData?.text}
                    </Styled.CustomButton>
                    <ConditionProgramButton onClick={onClickDownloadLink} />
                </>
            );
    }
};
