import React, { ChangeEvent, FC, FocusEvent, useState } from 'react';
import {
    MAX_MESSAGE_TEXTAREA_LENGTH
} from '~/src/components/blocks/clients-request/additionally-block/additionally-block.constants';
import type { TextareaProps } from './textarea.types';
import * as Styled from './textatea.styles';

/**
 * Used in Module Federation
 */
const Textarea: FC<TextareaProps> = ({
    value,
    required,
    name,
    label,
    onTextChange,
    placeholder,
    maxLength,
    onBlurHandler,
    onFocusHandler,
    hasError,
    disabled,
    height,
}) => {
    const [localValue, setLocalValue] = useState('');
    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onTextChange && onTextChange(event);
        setLocalValue(event.target?.value || '');
    };

    const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
        onBlurHandler && onBlurHandler(event);
    };

    const onFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
        onFocusHandler && onFocusHandler(event);
    };

    const getValueLengthCounter = () => `${localValue?.length || 0}/${MAX_MESSAGE_TEXTAREA_LENGTH}`;

    return (
        <Styled.TextareaWrapper data-testid={'container'}>
            <Styled.Label hasError={hasError}>
                <span>{label}</span>
                {required && <Styled.Star data-testid={'star'}>&#42;</Styled.Star>}
            </Styled.Label>
            <div>
                <Styled.Textarea
                    hasError={hasError}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    data-testid={'textarea'}
                    maxLength={maxLength}
                    required={required}
                    disabled={disabled}
                    height={height}
                />
                <Styled.TextareaValueCounter>
                    {getValueLengthCounter()}
                </Styled.TextareaValueCounter>
            </div>
        </Styled.TextareaWrapper>
    );
};

export default Textarea;