import AdvOne from '~/src/images/advantages/adv-one.svg';
import AdvTwo from '~/src/images/advantages/adv-two.svg';
import AdvThree from '~/src/images/advantages/adv-three.svg';

export const TEXTS_ADVANTAGES = {
    title: 'Преимущества агентской программы',
    advantages: [
        {
            id: 1,
            img: AdvOne,
            text: 'Вознаграждение в виде процента от оборота клиента с\u00A0МТС в течение года после подключения',
        },
        {
            id: 2,
            img: AdvTwo,
            text: 'Передача клиентов через личный кабинет,  API и\u00A0реферальные ссылки',
        },
        {
            id: 3,
            img: AdvThree,
            text: 'Партнёрство с\u00A0крупной стабильной компанией с\u00A0многомиллионной аудиторией',
        },
    ],
};
