import React, { FC } from 'react';
import { PERSONAL_CABINET_TEXT_DATA } from '~/src/components/blocks/main/personal-cabinet/personal-cabinet.constants';
import { IMAGE_QUALITY } from '~/src/models/constants/image-quality.constants';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import { useMatchMedia } from '~/src/hooks/use-match-media.hook';
import { MediaWidthEnum } from '~/src/models/enums/media-width.enum';
import PersonalCabinetImage from '~/src/images/personal-cabinet/personal-cabinet.png';
import MiddleBackground from '~/src/images/background/background-middle.png';
import MobileBackground from '~/src/images/background/background-mobile.png';
import * as Styled from './personal-cabinet.styles';

export const PersonalCabinet: FC = () => {
    const [isMobile] = useMatchMedia(MediaWidthEnum.mobile);

    const ImageContainer = ({ children }: { children: JSX.Element }) => {
        return isMobile ? (
            <Styled.ImgSmallContainer>{children}</Styled.ImgSmallContainer>
        ) : (
            <Styled.ImgBigContainer>{children}</Styled.ImgBigContainer>
        );
    };

    return (
        <Styled.Container>
            <Styled.BackgroundMiddle>
                <Styled.ImageBackground
                    width={2000}
                    height={3393}
                    src={isMobile ? MobileBackground : MiddleBackground}
                    alt={'Фон'}
                    quality={isMobile ? IMAGE_QUALITY.MEDIUM_LOW : IMAGE_QUALITY.VERY_LOW}
                />
            </Styled.BackgroundMiddle>
            <ContentContainer>
                <Styled.Wrapper>
                    <Styled.PersonalCabinet>{PERSONAL_CABINET_TEXT_DATA.personalCabinet}</Styled.PersonalCabinet>
                    {isMobile ? (
                        <Styled.TextData>{PERSONAL_CABINET_TEXT_DATA.mobileClientData}</Styled.TextData>
                    ) : (
                        <>
                            <Styled.TextData>{PERSONAL_CABINET_TEXT_DATA.clientData}</Styled.TextData>
                            <Styled.TextData>{PERSONAL_CABINET_TEXT_DATA.paymentProduct}</Styled.TextData>
                        </>
                    )}
                </Styled.Wrapper>
                <ImageContainer>
                    <Styled.ImageCabinet
                        width={1440}
                        height={900}
                        src={PersonalCabinetImage}
                        alt={'Личный кабинет'}
                        quality={isMobile ? IMAGE_QUALITY.VERY_LOW : IMAGE_QUALITY.MEDIUM}
                    />
                </ImageContainer>
            </ContentContainer>
        </Styled.Container>
    );
};
