import React, { FC } from 'react';
import Image from 'next/image';
import { useMatchMedia } from '~/src/hooks/use-match-media.hook';
import { MediaWidthEnum } from '~/src/models/enums/media-width.enum';
import { TEXTS_ADVANTAGES } from '~/src/components/blocks/main/advantages/advantages.constants';
import { IMAGE_QUALITY } from '~/src/models/constants/image-quality.constants';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import * as Styled from './advantages.styles';

export const Advantages: FC = () => {
    const [isMobile] = useMatchMedia(MediaWidthEnum.mobile);

    return (
        <Styled.Advantages>
            <ContentContainer>
                <Styled.Title>{TEXTS_ADVANTAGES.title}</Styled.Title>
                <Styled.AdvantagesList>
                    {TEXTS_ADVANTAGES.advantages.map((advantage) => {
                        const { id, img, text } = advantage;
                        return (
                            <Styled.Advantage key={id}>
                                <Styled.ImageWrap isMobile={isMobile}>
                                    <Image
                                        src={img}
                                        width={84}
                                        height={83}
                                        alt={'Иконка'}
                                        quality={IMAGE_QUALITY.VERY_HIGH}
                                    />
                                </Styled.ImageWrap>
                                <span>{text}</span>
                            </Styled.Advantage>
                        );
                    })}
                </Styled.AdvantagesList>
            </ContentContainer>
        </Styled.Advantages>
    );
};
