import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import { MAX_1023, MAX_767, MIN_1920 } from '~/src/styles/tokens/break-points';

export const Faq = styled.section`
    width: 100%;
    padding: ${rem(120)} 0;
    display: grid;

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            padding: ${rem(160)} 0 ${rem(120)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            padding: ${rem(80)} 0 ${rem(100)};
        `,
    )};
`;

export const FaqHeader = styled.h2`
    ${FONTS.wide.medium.size44};
    text-transform: uppercase;

    ${mediaQuery(
        { minWidth: MIN_1920 },
        css`
            ${FONTS.wide.medium.size58};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            ${FONTS.wide.medium.size28};
        `,
    )};
`;

export const FaqBody = styled.div`
    padding-top: ${rem(20)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            padding-top: 0;
        `,
    )};
`;
