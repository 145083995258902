import styled, { css } from 'styled-components';
import { mediaQuery } from '~/src/styles/mixins';
import { rem } from '~/src/helpers/rem-converter.helper';
import { MAX_1919, MAX_767 } from '~/src/styles/tokens/break-points';
import { ContentContainer } from '~/src/styles/shared-css/content-container';
import Link from 'next/link';
import { COLORS } from '~/src/styles/tokens/colors';
import { FONTS } from '~/src/styles/style-helpers/font-helper';

export const Carousel = styled(ContentContainer)`
    margin-top: ${rem(64)};

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            margin-top: ${rem(40)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            max-width: 100%;
            margin: 0 ${rem(20)};
            margin-top: ${rem(24)};
        `,
    )};
`;

export const CarouselNavigationWrapper = styled.div`
    margin-top: ${rem(32)};
    width: 100%;
`;

export const AllProductsLink = styled(Link)`
    color: ${COLORS.textLightPrimaryLink};
    ${FONTS.wide.medium.size28};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    ${mediaQuery(
        { maxWidth: MAX_1919 },
        css`
            ${FONTS.wide.medium.size24};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            ${FONTS.wide.medium.size20};
        `,
    )};
`;
