import React, { FC, SyntheticEvent } from 'react';
import { SvgDownload } from '~/src/components/svg-components/download';
import { COLORS } from '~/src/styles/tokens/colors';
import { programConditionsLinkText } from './condition-program-button.constants';
import * as Styled from './condition-program-button.styles';

interface ConditionProgramButtonProps {
    onClick: (event: SyntheticEvent<HTMLElement>) => void;
}

export const ConditionProgramButton: FC<ConditionProgramButtonProps> = ({ onClick }) => {
    return (
        <Styled.DownloadLinkWrap onClick={onClick}>
            <SvgDownload fill={COLORS.textPrimaryLinkDark} />
            <Styled.DownloadLink href={''} rel={'noopener noreferer'}>
                {programConditionsLinkText}
            </Styled.DownloadLink>
        </Styled.DownloadLinkWrap>
    );
};
