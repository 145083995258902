import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { BORDER_RADIUS } from '~/src/styles/tokens/border-radius';
import { FONTS } from '~/src/styles/style-helpers/font-helper';
import MainButton from '~/src/components/form-components/main-button/main-button';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_567, MAX_767 } from '~/src/styles/tokens/break-points';
import { COLORS } from '~/src/styles/tokens/colors';
import { Z_INDEX } from '~/src/styles/tokens/z-index';

const FullScreenStyles = css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const FeedbackFormBackground = styled.div<{ isOpen?: boolean }>`
    ${FullScreenStyles};
    z-index: ${Z_INDEX.level4};
    background-color: ${COLORS.backgroundInverted};
    opacity: ${p => p.isOpen ? '0.4' : '0'};
    visibility: ${p => p.isOpen ? 'visible' : 'hidden'};
    transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            overflow-y: scroll;
        `,
    )};
`;

export const FeedbackFormWrapper = styled.div`
    ${FullScreenStyles};
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: ${rem(50)};
    z-index: ${Z_INDEX.level5};
    backdrop-filter: blur(3px);
  
    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            align-content: start;
            overflow-y: scroll;
            margin-top: 0;
        `,
    )};
`;

export const FeedbackForm = styled.form`
    display: block;
    min-width: ${rem(688)};
    height: fit-content;
    margin: 0 auto;
    padding: ${rem(24)};
    border-radius: ${BORDER_RADIUS.bigger12};
    background-color: ${COLORS.backgroundModal};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            min-width: 100%;
            padding: ${rem(12)};
        `,
    )};
  
    ${mediaQuery(
        { maxWidth: MAX_567 },
        css`
            min-width: 100%;
            min-height: 100%;
            margin: ${rem(50)} 0 0 0;
            padding: ${rem(32)} ${rem(20)};
            border-radius: ${BORDER_RADIUS.zero};
            overflow-y: scroll;
        `,
    )};
`;

export const Title = styled.h2`
    display: flex;
    justify-content: space-between;
    ${FONTS.compact.medium.size24};
  
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-bottom: ${rem(-20)};
            ${FONTS.sans.bold.size17};
        `,
    )};
`;

export const CloseIconButton = styled.button`
    display: flex;
    padding: 0;
    background: none;
    border: none;
`;

export const Blocks = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: ${rem(20)};
    row-gap: ${rem(20)};
    margin-top: ${rem(32)};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            grid-template-columns: 1fr;
            row-gap: 0;
        `,
    )};
`;

export const WrapperRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: ${rem(20)};
  
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            grid-template-columns: 1fr;
        `,
    )};
`;

export const InputBlock = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: start;
  
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            margin-top: ${rem(20)};
        `,
    )};
`;

export const IconContainer = styled.div<{ withInBigBlock?: boolean }>`
    position: absolute;
    top: ${(p) => (rem(p.withInBigBlock ? 49 : 37))};
    left: calc(100% - ${(p) => (rem(p.withInBigBlock ? 42 : 32))});
`;

export const CleaningButton = styled.button<{ withInBigBlock?: boolean }>`
    position: absolute;
    top: ${(p) => (rem(p.withInBigBlock ? 52 : 40))};
    left: calc(100% - ${(p) => (rem(p.withInBigBlock ? 40 : 30))});
    display: flex;
    padding: 0;
    background: none;
    border: none;
`;

export const TextareaBlock = styled.div`
    position: relative;
    margin-top: ${rem(20)};
`;

export const WarningText = styled.span<{ notNecessary?: boolean, isShow?: boolean }>`
    display: ${(p) => (p.isShow ? 'block' : 'none')};
    margin-top: ${rem(4)};
    color: ${(p) => (p.notNecessary ? COLORS.textSecondary : COLORS.textNegative)};
    ${FONTS.sans.regular.size12};
`;

export const WrapperWarningText = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: ${rem(4)};
  
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            grid-template-columns: 1fr;
        `,
    )};
`;

export const Agreement = styled.label`
    display: grid;
    grid-template-columns: max-content auto;
    max-width: max-content;
    margin: ${rem(32)} 0 ${rem(24)};
  
    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            display: grid;
            grid-template-columns: 1fr auto;
            margin-top: ${rem(32)};
        `,
    )};
`;

export const TextConsent = styled.span`
    display: block;
    ${FONTS.compact.regular.size17};
    cursor: pointer;
`;

export const LinkConsent = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: ${COLORS.accentActive};
`;

export const CustomButton = styled(MainButton)`
    width: unset;
    margin-top: ${rem(24)};
    padding: ${rem(10)} ${rem(24)};
    ${FONTS.sans.medium.size17};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
            max-width: 100%;
            margin-top: ${rem(32)};
        `,
    )};
`;
